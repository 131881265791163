import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import { ApiService } from './services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-assignment',
    templateUrl: './assignment.component.html',
    styleUrls:['./assignment.component.css']
})
export class AssignmentComponent implements OnInit {
    content: any;
    addAssignment: FormGroup;
    editAssignment: FormGroup;
    editContact: FormGroup;
    getAssignmentResponse: any = {};
    locationTypes: any[];
	dealershipTypes: any[];
	entityList: any[];
    subEntityList: any[];
    dataCategoryList:any = [];
    sEntity:any
    subEntity: any[];
    dataCategory:any;
    currentUser;
    reportId;
    @ViewChild('addAssignmentRef') addAssignmentForm;
    @ViewChild('editContactRef') editContactForm;
    constructor(
        private dialogRef: MatDialogRef<AssignmentComponent>,
        @Inject(MAT_DIALOG_DATA) public data : any,
        private frmBuilder: FormBuilder,
        private ApiService: ApiService,
        private route: ActivatedRoute) {
            this.dialogRef.disableClose = true;  
            this.content = data.dialogConfig.data;
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
    ngOnInit() {
        if(this.content.page == 'add-assignment') {
        this.addAssignment = this.frmBuilder.group({
            locationKey: [this.content.locationId],
            reporting_entity_location_code : ['', Validators.required],
            company_name:['', Validators.required],
            location_type:['', Validators.required],
            dealership_type:[''],
            entity:['', Validators.required],
            sub_entity:['', Validators.required],
            data_category:['', Validators.required],
            display_on_map:[true],
            status_notes:['', Validators.required],            
			firstName:[this.currentUser.lastName],
			lastName:[this.currentUser.lastName]
        });
        }
        if(this.content.page == 'edit-assignment') {
        this.addAssignment = this.frmBuilder.group({
            locationXrefKey: [this.content.assignmentObj.LocationXrefKey],
            reporting_entity_location_code:[this.content.assignmentObj.ReportingEntityLocationCode],
            company_name:[this.content.assignmentObj.CompanyName, Validators.required],
            location_type:['', Validators.required],
            dealership_type:[this.content.assignmentObj.DealershipTypeKey],
            entity:[this.content.assignmentObj.EntityCode, Validators.required],
            sub_entity:[this.content.assignmentObj.SubEntityCode, Validators.required],
            data_category:[this.content.assignmentObj.DataCategoryKey, Validators.required],
            display_on_map:[this.content.assignmentObj.DisplayOnMap],
            status_notes:['', Validators.required],            
			firstName:[this.currentUser.lastName],
			lastName:[this.currentUser.lastName]
        });
        }
        if(this.content.page == 'status-assignment') {
            this.addAssignment = this.frmBuilder.group({
                locationXrefKey: [this.content.locationXrefKey],               
                status_notes:['', Validators.required],           
                firstName:[this.currentUser.lastName],
                lastName:[this.currentUser.lastName]
            });
        }
        if(this.content.page == 'edit-contact') { 
            this.editContact = this.frmBuilder.group({
                firstName: [this.content.contactObj.firstName, Validators.required],               
                lastName:[this.content.contactObj.lastName, Validators.required],           
                emailId:[this.content.contactObj.emailId, Validators.email],
                roleName:[this.content.contactObj.roleName, Validators.required],
                contactType:[this.content.contactObj.contactType, Validators.required],
                id:[this.content.contactObj.id, Validators.required]
            });
        }
        if(this.content.page == 'add-contact') { 
            this.editContact = this.frmBuilder.group({
                firstName: [null, Validators.required],               
                lastName:[null, Validators.required],           
                emailId:[null, Validators.email],
                roleName:[null, Validators.required],
                contactType:[null, Validators.required]
            });
        }        
        if(this.content.page == 'add-assignment' || this.content.page == 'edit-assignment'){
        this.ApiService.getAssignmentScreenData().subscribe( result => {
            this.getAssignmentResponse = result;
            this.locationTypes = result.locationTypes;
	        this.dealershipTypes = result.dealershipTypes;
	        this.entityList = result.entity;
            this.subEntityList = result.subEntity;
            //this.dataCategoryList = result.dataCategory;
			},
            error => {});
        }
     }
     get reporting_entity_location_code() { return this.addAssignment.get('reporting_entity_location_code'); }
     get company_name() { return this.addAssignment.get('company_name'); }
     get location_type() { return this.addAssignment.get('location_type'); }
     get dealership_type() { return this.addAssignment.get('dealership_type'); }
     get entity() { return this.addAssignment.get('entity'); }
     get sub_entity() { return this.addAssignment.get('sub_entity'); }
     get data_category() { return this.addAssignment.get('data_category'); }
     get status_notes() { return this.addAssignment.get('status_notes'); }

     get firstName() {return this.editContact.get('firstName');}
     get lastName() {return this.editContact.get('lastName');}
     get emailId() {return this.editContact.get('emailId');}
     get roleName() {return this.editContact.get('roleName');}
     get contactType() {return this.editContact.get('contactType');}
     
    onSelectEntity(event){
        //this.dataCategory = this.ApiService.getObjects(this.dataCategoryList,event.value);
        var x = event.value;
		const body = {
			"entityCode" : x
		};
        this.dataCategory = this.ApiService.getDataCategory(body).subscribe(result => {
			//           console.log(result);
                        this.dataCategory= result.dataCategory[0];
                        this.dataCategoryList = this.dataCategory;
			        },
			            error => {
                    });;
            this.gsubEntity(body);
         
        // this.subEntity = this.ApiService.getObjects(this.subEntityList,event.value);       
     }
     gsubEntity(body){
     
        this.sEntity = this.ApiService.getSubEntity(body).subscribe(result => {
            //           console.log(result);
                        this.subEntity= result.subEntity[0];
                        this.subEntityList =  this.subEntity;
                    },
                        error => {
                    });;    
     }
    onSubmitAddAssignment () {
        if(!this.addAssignment.valid)
		return;
        this.ApiService.addAssignment(this.addAssignment.value)
	     .subscribe( result => {
            this.closeDialog(true);
            this.addAssignmentForm.resetForm();			
			},
            error => {				
			});
    }
    onSubmitEditAssignment(){
        if(!this.addAssignment.valid)
		return;
        this.ApiService.updateAssignment(this.addAssignment.value)
	     .subscribe( result => {
            this.closeDialog(true);
            this.addAssignmentForm.resetForm();			
			},
            error => {				
			});
    }
    onSubmitStatusAssignment() { 
        if(!this.addAssignment.valid)
		return;
        this.ApiService.assignmentStatus(this.addAssignment.value)
	     .subscribe( result => {
            this.closeDialog(true);
            this.addAssignmentForm.resetForm();			
			},
            error => {				
			});  
    }
    onSubmitEditContact(){
        if(!this.editContact.valid)
        return;
        if(this.content.page == 'edit-contact'){
        this.ApiService.updateContact(this.editContact.value)
	     .subscribe( result => {
            this.closeDialog(true);
            this.ApiService.showSuccess('Success!',result.Success);
            this.editContactForm.resetForm();			
			},
            error => {		
                //this.ApiService.showError('Failure!');			
            });
        } else {
            this.ApiService.addContact(this.editContact.value)
	     .subscribe( result => {
            this.closeDialog(true);
            this.ApiService.showSuccess('Success!',result.Success);
            this.editContactForm.resetForm();			
			},
            error => {		
                //this.ApiService.showError('Failure!');			
            });
        }
    }
    removeContact() {
        this.ApiService.removeContact(this.content.contactObj.id)
	     .subscribe( result => {
            this.closeDialog(true);
            this.ApiService.showSuccess('Success!',result.Success);            			
			},
            error => {		
                //this.ApiService.showError('Failure!');		
			});
    }
    closeDialog(status){
        this.dialogRef.close(status);
    }
}