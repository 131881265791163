import { Component, OnInit,AfterViewInit,ViewEncapsulation,ViewChild} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog'; 
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../../dialog.component';
import { AdminDialogComponent } from '../../adminDialog.component';
import { entityDataService } from '../../services/entityData.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manageEntity',
  templateUrl: './manageEntity.component.html',
  styleUrls: ['./manageEntity.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ManageEntityComponent implements OnInit,AfterViewInit { 
  EntityId;
  matcher = new MyErrorStateMatcher();
  entityForm:FormGroup;
  entityTypes:any;    
  countryList:any;
  currentUser:any;
  successBlock:boolean = false;
  successMsg: string;
  errorBlock:boolean = false;
  errorMsg: string;
  entityObj:any;
  @ViewChild('addEntityRef') addEntityForm;
  constructor(public dialog: MatDialog,private ApiService: ApiService,private route: ActivatedRoute,
    private frmBuilder:FormBuilder, private router: Router,private entityDataService:entityDataService) {      
      this.route.params.subscribe( params => {
        this.EntityId = params.EntityId;});
      this.entityObj = this.entityDataService.entityObj;
      this.entityTypes = [{'entityLabel':'Finance'},{'entityLabel':'Management'},{'entityLabel':'Manufacturing'},
      {'entityLabel':'Partner - Dealer'},{'entityLabel':'R&D'},{'entityLabel':'Sales & Service'}];
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
  ngOnInit() { 
    if(this.EntityId == 0) {
      this.entityForm = this.frmBuilder.group({
        entityCode:[null,Validators.required],
        entityName:[null,Validators.required],
        entityType:[null,Validators.required],
        countryCode:[null,Validators.required],
        statusNotes:[null,Validators.required],
        name:[this.currentUser.firstName+' '+this.currentUser.lastName],
        email:[this.currentUser.emailAddress]
      });
    } else {      
      this.entityForm = this.frmBuilder.group({
        entityId:[],
        entityCode:[{value: null, disabled: true},Validators.required],
        entityShort:[],
        entityName:[null,Validators.required],
        entityType:[null,Validators.required],
        countryCode:[null,Validators.required],
        statusNotes:[null,Validators.required],
        name:[this.currentUser.firstName+' '+this.currentUser.lastName],
        email:[this.currentUser.emailAddress]
      });
      if(this.entityObj){
        this.entityForm.patchValue({
        entityId:this.entityObj.EntityCode,
        entityCode:this.entityObj.EntityShort,
        entityShort:this.entityObj.EntityShort,
        entityName:this.entityObj.EntityLong,
        entityType:this.entityObj.EntityType,
        countryCode:this.entityObj.CountryKey,
        statusNotes:this.entityObj.Status_Note_Txt
        });
      } else {
        this.router.navigate(['databook/manageEntity',0,1020]);
      }
    }      
    this.getAllCountry();
  } 
  ngAfterViewInit() { }
  getAllCountry() {
    this.ApiService.getAllCountry().subscribe( result => {      
      this.countryList = result.country;
      },
      error => {
        
      });
  }
  onSubmitEntity(){
    if(!this.entityForm.valid)
    return false;
    if(this.EntityId == 0) {
      this.ApiService.addEntity(this.entityForm.value)
	     .subscribe( result => {
        if(result.error) {
          this.errorBlock = true;
          this.errorMsg = result.message;
          this.toggleMessage('error');
        } else {
        this.addEntityForm.resetForm();
        this.successBlock = true;
        this.successMsg = result.Success;
        this.toggleMessage('success');
        }
			},
      error => {
        this.errorDisplay(error);								
			});
    } else {
      this.ApiService.editEntity(this.entityForm.value)
      .subscribe( result => {
       this.successBlock = true;
       this.successMsg = result.Success;
       this.toggleMessage('success');
     },
     error => {
       this.errorDisplay(error);								
     });
    }
    
  } 
  openDeactivateDialog(status) {
    var msg = 'ACTIVATE';
    var title = 'ACTIVATE';
    if(status == 'Active') {
      msg = 'DEACTIVATE';
      title = 'DEACTIVATE';
    }
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: msg,
			title: title,
      page:'deactivate-entity',
      entityId:this.EntityId,
      status:status,
      obj:this.entityObj
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.router.navigate(['databook/admin',1010]);
				}				
		}); 
  }
  back(type){
    if(type == 'admin') {
     this.router.navigate(['databook/admin',1010]);
    }
  } 
  reset(){
    this.addEntityForm.resetForm();
  }
  errorDisplay(error) {
    this.errorBlock = true;
    if(error.status == "500"){
      this.errorMsg = environment.errorMsg500;
    } else if(error.status == "400"){
      this.errorMsg = error.error.Error;
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type){
    setTimeout(() => {
     if(type == 'success'){
      this.successBlock = false;
     } else {
      this.errorBlock = false;
     }      
  }, 5000);
  }
  
}
/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}