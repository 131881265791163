import { Component, OnInit, ViewChild,ElementRef,Injectable} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {Chart} from 'chart.js'
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
  
export class ActivityComponent implements OnInit{  
@ViewChild('screenView') screenView: ElementRef;	
@ViewChild('userView') userView: ElementRef;	
	constructor(private ApiService: ApiService,private route: ActivatedRoute) { }
	ngOnInit() {
		// this empty method is required.
	} 
	ngAfterViewInit() {
		const screenViewctx = this.screenView.nativeElement.getContext('2d');
		const userViewctx = this.userView.nativeElement.getContext('2d');
		const screenViewData = {
            labels: ['John', 'David', 'Andro', 'Asley', 'Andrew', 'Amy', 'Alba','Kim', 'Melisa', 'Victoria', 'Brain', 'Steven', 'Robert', 'Scott scadded'],
            datasets: [
                {
				label: 'Home',
				fill: false,
				backgroundColor:'#3a0c00',
				borderColor: 'grey',
                    "data": [20,60,10,50,11,5,6,20,60,10,50,11,5,6]
                    
                },
				{label: 'Economic Impact',
				fill: false,
				backgroundColor:"#003a18",
				borderColor: 'grey',
                    "data": [24,12,7,50,41,3,9,24,12,7,50,41,3,9]                    
                },
				{label: 'Map',
				fill: false,
				backgroundColor:'#5b5b5b',
				borderColor: 'grey',				
                    "data": [12,0,29,17,5,13,19,12,0,29,17,5,13,19]                    
                }				
				]
        }; 
		this.createGraph(screenViewctx,screenViewData,'Users','No of Hits');
		const userViewData = {
            labels: ['Home', 'Admin', 'Economic Impact', 'Reports'],
            datasets: [
                {
				label: 'David',
				fill: false,
				backgroundColor:'#005ce6',
                    "data": [31,30,18,20,11]                    
                },
				{label: 'Brain',
				fill: false,
				backgroundColor:"#ff1a1a",
                    "data": [24,12,7,30,41]                    
                },
				{label: 'Victoria ',
				fill: false,
				backgroundColor:'#166b65',				
                    "data": [12,0,29,17,15]                    
                },
				{label: 'Robert ',
				fill: false,
				backgroundColor:'#d89536',				
                    "data": [22,11,29,9,30]                    
                }				
				]
        };
		this.createGraph(userViewctx,userViewData,'Screens','No of Hits'); 
	}
createGraph(ctx,data,XlabelString,YlabelString){
const screenViewChart = new Chart(
            ctx,
            {
                "type": 'line',
                "data": data,				
                "options": {                                        
					responsive:false,
maintainAspectRatio: false,					
					scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: XlabelString
						},
						 ticks: {
            stepSize: 1,
            min: 0,
            autoSkip: false
        }
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: YlabelString
						}
					}]
				},
					legend: {
					position: 'bottom',
					display: true,
					fullWidth:true,					
					},					
					tooltips: {
						enabled: true
					}
                }
            }
        );	
}
	
}