import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateSplit'})

export class DateSplitPipe implements PipeTransform {
   transform(value:string, splitText:string,type:number):string {
    var splitArray = value.split(splitText);
    return splitArray[type];
  }
}
