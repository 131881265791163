import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { NgHttpLoaderModule } from 'ng-http-loader'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule}  from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule,MatSpinner} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import {MatSelectModule} from '@angular/material/select';
import { DatePickerComponent } from './utility/date-picker/date-picker.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './breadcrumb.component';
import { DialogComponent } from './dialog.component';
import { MessageComponent } from './message.component';
import { AssignmentComponent } from './assignment.component';
import { AdminDialogComponent } from './adminDialog.component';
import { HomeComponent } from './home/home.component';
import { DistrictComponent } from './district-fact/district.component';
import { ContactComponent } from './contacts/contact.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { GlossaryDetailComponent } from './glossary/glossaryDetail.component';
import { GlossaryReportComponent } from './glossary/glossaryReport.component';
import { ReportsComponent } from './admin/reports.component';
import { ActivityComponent } from './admin/activity.component';
import { LocationComponent } from './admin/location.component';
import { ManageLocationComponent } from './admin/manageLocation.component';
import { AdminstrationComponent } from './admin/adminstration.component';
import { EconomicImpactComponent } from './admin/economic-impact/economicImpact.component';
import { FileUploadComponent } from './admin/file-upload/fileUpload.component';
import { ManualEntryComponent } from './admin/manual-entry/manualEntry.component';
import { ManageContentsComponent } from './admin/manage-contents/manageContents.component';
import { ManageCategoryComponent } from './admin/manage-category/manageCategory.component';
import { ManageEntityComponent } from './admin/manage-entity/manageEntity.component';
import { ManageSubEntityComponent } from './admin/manage-subEntity/manageSubEntity.component';
import { ManageProvidersComponent } from './admin/manage-providers/manageProviders.component';
import { ManageReportingPeriodsComponent } from './admin/manage-reporting-periods/manageReportingPeriods.component';
import { DataProviderDashboardComponent } from './admin/data-provider-dashboard/dataProviderDashboard.component';
import { ApiService } from './services/api.service';
import { PagerService } from './services/pager.service';
import { DownloadService } from './services/download.service';
import { entityDataService } from './services/entityData.service';
import { AuthGuard } from './guards';
import { AuditSplitPipe } from './utility/auditSplit.pipe';
import { ParseIntPipe } from './utility/parseInt.pipe';
import { DateSplitPipe } from './utility/dateSplit.pipe';
import { EncryptService } from './services/encrypt.service';
import { ManualEntryFields } from './utility/manualEntryFields';
import { CustomValidator } from './utility/customValidator';
import { CongressionalDistrictComponent } from './admin/congressional-district/congressionalDistrict.component';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { ExcelService} from '../app/admin/file-upload/excel.service';
import { LegislativeDataRefreshComponent } from './admin/legislative-data-refresh/legislative-data-refresh.component'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

const appRoutes: Routes = [
    { path: 'access_token', redirectTo: '/', pathMatch: 'full' }, 
    { path: '',  component: HomeComponent, canActivate: [AuthGuard],data: {
          breadcrumb: "Dashboard"
        }}, 
    { path: 'databook/dashboard/:screenId', component: HomeComponent, canActivate: [AuthGuard],data: {
          breadcrumb: "Dashboard"
        }},
        { path: 'databook/district-fact/:screenId', component: DistrictComponent, canActivate: [AuthGuard],data: {
          breadcrumb: "District Fact"
        }},        
    { path: 'databook/reports/:screenId', component: ReportsComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Reports"
          }},
      { path: 'databook/reports/:screenId/:reportPage/:reportId', component: EconomicImpactComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Reports Economic"
          }},				
      { path: 'databook/activity/:screenId', component: ActivityComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "User Activity"
          } },
      { path: 'databook/location/:screenId', component: LocationComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Location"
          } },
      { path: 'databook/location/:locationId/:screenId', component: LocationComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Location"
          } },
      { path: 'databook/manage-location/:screenId', component: ManageLocationComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Manage Location"
          } },
      { path: 'databook/admin/:screenId', component: AdminstrationComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Adminstration"
          } },
      { path: 'databook/fileUpload/:dataCategoryId/:entityId/:screenId', component: FileUploadComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Report File Upload"
          } },
      { path: 'databook/manualEntry/:dataCategoryId/:entityId/:screenId', component: ManualEntryComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Report File Upload"
          } },
      { path: 'databook/manageContents/:sectionId/:screenId', component: ManageContentsComponent , canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Contents"
          } },
      { path: 'databook/manageCategory/:CategoryId/:screenId', component: ManageCategoryComponent , canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Categoery"
          } },
      { path: 'databook/manageEntity/:EntityId/:screenId', component: ManageEntityComponent , canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Contents"
          } },
      { path: 'databook/manageSubEntity/:SubEntityId/:screenId', component: ManageSubEntityComponent , canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Contents"
          } },
      { path: 'databook/contacts/:screenId', component: ContactComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Contacts"
          } },          
      { path: 'databook/glossary/:screenId', component: GlossaryComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Glossary"
          } },
      { path: 'databook/glossary/detail/:dataCategoryId/:dataCategoryName/:screenId', component: GlossaryDetailComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Glossary / Detail"
          } },
      { path: 'databook/glossary/report/:reportId/:screenId', component: GlossaryReportComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Glossary / Report"
          } },
      { path: 'databook/manageProviders/:screenId', component: ManageProvidersComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Providers"
          } },
      { path: 'databook/manageProviders/:providerId/:screenId', component: ManageProvidersComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Providers"
          } },

      { path: 'databook/legislativeDataRefresh/:screenId', component: LegislativeDataRefreshComponent, canActivate: [AuthGuard],data: {
        breadcrumb: "Admin / Legislative Data Refresh"
      } },

      { path: 'databook/ReportingPeriods/:screenId', component: ManageReportingPeriodsComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Reporting Period"
          } },
      { path: 'databook/ReportingPeriods/:newReportingPeriod/:screenId', component: ManageReportingPeriodsComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Manage Reporting Period"
          } },
      { path: 'databook/DataProviderDashboard/:screenId', component: DataProviderDashboardComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Admin / Data Provider Dashboard"
          } },
      { path: 'databook/congressionalDistrict/:screenId', component: CongressionalDistrictComponent, canActivate: [AuthGuard],data: {
            breadcrumb: "Reports congressional District"
          }}                  
	]
 
@NgModule({
  declarations: [
  AppComponent,
  BreadcrumbComponent,
  HomeComponent,
  DistrictComponent,
  GlossaryComponent,
  GlossaryDetailComponent,
  GlossaryReportComponent,
  ContactComponent,	
	ReportsComponent,
	EconomicImpactComponent,
	ActivityComponent,
	LocationComponent,
  DialogComponent,
  ManageLocationComponent,
  MessageComponent,
  AssignmentComponent,
  AdminDialogComponent,
  AdminstrationComponent,
  FileUploadComponent,
  ManualEntryComponent,
  ManageContentsComponent,
  ManageCategoryComponent,
  ManageEntityComponent,
  ManageSubEntityComponent,
  ManageProvidersComponent,
  ManageReportingPeriodsComponent,
  DataProviderDashboardComponent,
  AuditSplitPipe,
  ParseIntPipe,
  DateSplitPipe,
  DatePickerComponent,
  CongressionalDistrictComponent,
  LegislativeDataRefreshComponent
  ],
  exports:[MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatToolbarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatTooltipModule ,
  MatCheckboxModule,
  MatExpansionModule,
  MatTabsModule,
  MatListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatAutocompleteModule
  ],
  imports: [BrowserModule,BrowserAnimationsModule,FormsModule,
  ReactiveFormsModule,
  ToastrModule.forRoot({
    timeOut: 10000,
    positionClass: 'toast-top-center',
    preventDuplicates: true,
    closeButton:true,
    progressBar:true
  }),
  HttpClientModule,
  NgHttpLoaderModule,
  RouterModule.forRoot(appRoutes),
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatToolbarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatSelectModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  NgxMatSelectSearchModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatTabsModule,
  MatListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,MatIconModule
  ],
  providers: [ApiService,EncryptService,DownloadService,entityDataService,
    AuthGuard,PagerService,ExcelService,
    MatDatepickerModule,ManualEntryFields,CustomValidator,{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE]
  },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  {provide: LocationStrategy, useClass: HashLocationStrategy},
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents: [
    MatSpinner,DialogComponent,MessageComponent,AssignmentComponent,AdminDialogComponent
    ]
}) 
export class AppModule {
  
 }