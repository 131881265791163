import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
    content: any;
    constructor(
        private dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data : any) { this.content = data.data; }
    ngOnInit() { }
    saveDialog(): void {
        this.dialogRef.close(true);
    }
    closeDialog(): void {        
        this.dialogRef.close(false);
    }
}