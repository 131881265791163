import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { ApiService } from '../services/api.service';
import { DialogComponent } from '../dialog.component';
import { AssignmentComponent } from '../assignment.component';
import { PagerService } from '../services/pager.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {	
	communicationContacts:any[] = [];
	dataCollectionTeamContacts:any[] = [];
	pager: any = {};
	CommContactsList: any[];
	contacts: any[];
	matcher = new MyErrorStateMatcher();
	postContact: FormGroup;
	currentUser :any;	
	constructor(private ApiService: ApiService,private pagerService: PagerService,
		private frmBuilder: FormBuilder,
		private dialog: MatDialog) {
			this.currentUser = JSON.parse(localStorage.getItem('currentUser'));			
		 }
	ngOnInit() {		
		this.getContacts();		 
	}
   getContacts(){
	this.dataCollectionTeamContacts = [];
	this.communicationContacts = [];
	this.ApiService.getContacts().subscribe(
		data => {
			  this.contacts = data.Contacts;
			  for(var i=0;i<this.contacts.length;i++){
					if(this.contacts[i].contactType == 'DATA COLLECTION'){
						this.dataCollectionTeamContacts.push(this.contacts[i]);
					}
					if(this.contacts[i].contactType == 'TOYOTA COMMUNICATIONS'){
						this.communicationContacts.push(this.contacts[i]);
					}				
			  }
              //this.setPage(1);
		}
	);  
   }
   setPage(page: number) {
	// get pager object from service
	this.pager = this.pagerService.getPager(this.communicationContacts.length, page,4);
	// get current page of items
	this.CommContactsList = this.communicationContacts.slice(this.pager.startIndex, this.pager.endIndex + 1);
	}
	editContact(obj){
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "EDIT CONTACT",
			title: 'EDIT CONTACT',
			page:'edit-contact',			
			buttonText:'Update',
			contactObj:obj
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AssignmentComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
					this.getContacts();
				}
				
		});
	}	
	addContact() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "ADD CONTACT",
			title: 'ADD CONTACT',
			page:'add-contact',
			buttonText:'Submit',
			contactObj:null
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AssignmentComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
					this.getContacts();
				}
				
		});
	}
	deleteContact(obj) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "DELETE CONTACT",
			title: 'DELETE CONTACT',
			page:'delete-contact',
			contactObj:obj
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AssignmentComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
					this.getContacts();
				}
				
		});
	}

}
/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
	  const isSubmitted = form && form.submitted; 
	  return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
  }