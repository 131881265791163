import { Component, OnInit, ViewChild,AfterViewInit,ViewEncapsulation} from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm,FormArray } from '@angular/forms';
import {PageEvent} from '@angular/material/paginator'; 
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../../dialog.component';
import { AdminDialogComponent } from '../../adminDialog.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manageReportingPeriods',
  templateUrl: './manageReportingPeriods.component.html',
  styleUrls: ['./manageReportingPeriods.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ManageReportingPeriodsComponent implements OnInit,AfterViewInit{	
  columnsToDisplay = ['title','datacategory'];
  dataCategoryColumns = ['selectItem','datacategorykey','frequency','startDate','endDate','dueDate'];
  openReportingData = new MatTableDataSource();
  closedReportingData = new MatTableDataSource();
  dataCategoryData = new MatTableDataSource();
  @ViewChild(MatSort) closedReportingsort: MatSort;
  @ViewChild(MatPaginator) closedReportingpaginator: MatPaginator;
  pageLength: number;
  pageSize: number = 2;
  dataLength: number;
  newReportingPeriod: string;
  reportingPeriodsForm: FormGroup;
  openReportingPeriodEnable: boolean = false;
  successBlock:boolean = false;
  successMsg: string;
  errorBlock:boolean = false;
  errorMsg: string;
  matcher = new MyErrorStateMatcher();
  pageEvent: PageEvent;
  selectAll: boolean = false;
  yearList:any[];
  currentYear:number;
  currentUser:any;
  constructor(public dialog: MatDialog,private ApiService: ApiService,private activeRoute: ActivatedRoute,
    private frmBuilder:FormBuilder, private router: Router) {      
      this.activeRoute.params.subscribe(queryParams => {
        this.newReportingPeriod = queryParams.newReportingPeriod;	
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));	
      });
  }
  ngOnInit() {
    this.getReportingPeriods();
    if(this.newReportingPeriod) { 
    this.currentYear =  new Date().getFullYear();  
    this.yearList = this.ApiService.getYears(this.currentYear,5); 
    this.reportingPeriodsForm = this.frmBuilder.group({
      year: [this.currentYear,Validators.required],
      reportingPeriod:['',Validators.required],
      endDate: [formatDate(new Date(),'yyyy-MM-dd','en')],
      username:[this.currentUser.firstName +" "+ this.currentUser.lastName],
      email:[this.currentUser.emailAddress],
      dataCategoryList: this.frmBuilder.array([
          
      ])
    });
    this.getDatacategories();   
    } 

  } 
  ngAfterViewInit() {    
  }  
  get year() { return this.reportingPeriodsForm.get('year'); }
  get endDate() { return this.reportingPeriodsForm.get('endDate');}

  getDatacategories(){
    this.ApiService.getDatacategories()
    .subscribe( result => {        
      this.dataCategoryData.data = result.Datacategories;    
      const control  = <FormArray>this.reportingPeriodsForm.controls['dataCategoryList'];
      result.Datacategories.forEach(item => {
        control.push(this.patchValues(item))
      });      
    },
    error => {
               
    });
  }
  patchValues(obj) {
    return this.frmBuilder.group({
      endDate: [formatDate(new Date(),'yyyy-MM-dd','en')],
      startDate:[formatDate(new Date(),'yyyy-MM-dd','en')],
      dueDate:[formatDate(new Date(),'yyyy-MM-dd','en')],
      selectItem:[],
      dataCategoryId:[obj.DataCategoryKey]
    })  
  }
  selectAllClick(){
    this.selectAll = !this.selectAll;
  }
  getReportingPeriods(){
    this.ApiService.getAllReportingperiods().subscribe(
      data => {
        if(data.Reportingperiods[0].Status == 'Open') {
          this.openReportingPeriodEnable = true;
          this.setOpenReport(data.Reportingperiods);      
          this.setCloseReport(data.Reportingperiods,true);
        } else {
          this.openReportingPeriodEnable = false;
          this.setCloseReport(data.Reportingperiods,false);
        }
      },error => {
        
      });
  }
  setOpenReport(item) {
    var temp = item.splice(0,1);
    this.openReportingData.data = temp;
  }
  setCloseReport(item,splice) {
    if(splice){
      //item.splice(0,1)
    }
    this.closedReportingData.data = item;
    this.dataLength = item.length;
    this.pageLength = Math.ceil(this.dataLength / this.pageSize);
    this.closedReportingData.sort = this.closedReportingsort;          
    setTimeout(() => this.closedReportingData.paginator = this.closedReportingpaginator);
  }
  reopen(item){
  var data = {"field":item.ReportingPeriod,
                "reportingperiodkey":item.ReportingPeriodKey,
                "username":this.currentUser.firstName +" "+ this.currentUser.lastName,
                "email":this.currentUser.emailAddress };
  this.ApiService.reopenReportingperiod(data).subscribe(
  data => {
    this.getReportingPeriods();
  },error => {    
    this.errorDisplay(error);
  });
  }  
  closeReportDialog() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "CLOSE REPORTING PERIOD",
      page:"close-reporting-period",
      obj:this.openReportingData.data
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
        if(data){
        this.getReportingPeriods();
        }								
		}); 
  }
  updateExchangeDialog(){
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "UPDATE EXCHANGE RATES",
      page:"update-exchange-rates",
      obj:this.openReportingData.data
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.successBlock = true;
          this.successMsg = '<strong>Currency exchange rates have been succesfully updated.</strong>';
          this.toggleMessage('success');          
				}				
		});  
  }
  errorDialog(msg) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: msg,
      page: "error-message",
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'750px'});  
  }
  onSubmitReportingPeriods(){
    var selectedItem = false;
    var tempArray = [],selectedDCArray = [];
    if(!this.reportingPeriodsForm.valid){
      return;
    } else if(this.reportingPeriodsForm.value.endDate == "" || this.reportingPeriodsForm.value.endDate == null) {
      this.errorDialog('Please select reporting period end date.');
      return;
    }	else {
      this.reportingPeriodsForm.value.dataCategoryList.forEach(function (item) {
        if(item.selectItem){
          selectedItem = true;
          tempArray.push(item.endDate);
          tempArray.push(item.startDate);
          tempArray.push(item.dueDate);
          selectedDCArray.push(item);
        }
      });
      if(!selectedItem) {
        this.errorDialog('At least one data category need to be chosen.');
        return;
      } else if(tempArray.indexOf(null) !== -1) {
        this.errorDialog('One or more selected data categories have an empty Start Date, End Date, or Due Date.');
        return;
      } else {
        this.reportingPeriodsForm.value.dataCategoryList = selectedDCArray;
        this.ApiService.openNewReportingperiod(this.reportingPeriodsForm.value)
        .subscribe( result => {
         if(result) {
           this.router.navigate(['databook/ReportingPeriods',1017]);
         } else {
           this.errorBlock = true;
           this.errorMsg = result.message;
           this.toggleMessage('error');
         }        
       },
       error => { 
         this.errorDisplay(error);			
       });
      }     
    }	     
  }
  addNewReportingPeriod(){
    this.router.navigate(['databook/ReportingPeriods','openNew',1017]);
  }
  back(type){
    if(type == 'reporting') {
     this.router.navigate(['databook/ReportingPeriods',1017]);
    }
  }
  errorDisplay(error) {
    this.errorBlock = true;
    if(error.status =="500"){
      this.errorMsg = environment.errorMsg500;
    } else if(error.status =="400"){
      if(error.error.Error){
        this.errorMsg = error.error.Error;
      } else {
        this.errorMsg = error.error.Message;
      }
      
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type){
    setTimeout(() => {
     if(type == 'success'){
      this.successBlock = false;
     } else {
      this.errorBlock = false;
     }      
  }, 5000);
  }
  
}
/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}