import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from './services/api.service';
import { ActivatedRoute } from '@angular/router';
import { CustomValidator } from './utility/customValidator';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { environment } from '../environments/environment';
import {interval} from "rxjs";

@Component({
    selector: 'app-adminDialog',
    templateUrl: './adminDialog.component.html',
    styleUrls:['./adminDialog.component.css']
})
export class AdminDialogComponent implements OnInit {
    content: any;    
    addAdmin: FormGroup;
    addCompany: FormGroup; 
    addSection: FormGroup;
    addContent: FormGroup;
    addEconomic:FormGroup;
    subEntityAssignmentForm: FormGroup;
    categoryStatusForm: FormGroup;
    entityStatusForm: FormGroup;
    subEntityStatusForm: FormGroup;
    currentUser;
    entityList: any[];
    subEntityList: any[];
    subEntity: any[];
    yearList:any[];
    titleList:any = ['CY Vehicle Unit Sale','CY Vehicle Production','CY Engine Production','CY Automatic Transmission Production'];
    progress;
    currentYear:number;
uploading = false;
uploadSuccessful = false;
@ViewChild('file') file;
public files: Set<File> = new Set();
uploadSuccess: boolean = false;
uploadError: boolean ; false;
uploadSuccessMsg: string;
uploadErrorMsg: string;
successBlock:boolean;
successMsg:string;
errorBlock:boolean;
errorMsg:string;
existingReportName:string;
    dataproviderKeys: string[] = ['DATAPROVIDER_NAME', 'EMAILADDRESS', 'PHONE'];
    dataproviderColumns: any = [{'label':'Name'},{'label':'Email'},{'label':'phone'}];	
    locationAssignmentKeys: string[] = ['ReportingEntityLocationCode', 'LocationName', 'Dealership_Type'];
    locationAssignmentColumns: any = [{'label':'Rep Entity Loc Code'},{'label':'Location Type'},{'label':'Dealership Type'}];
    subEntityKeys: string[] = ['SubEntityShort', 'SubEntityLong','Databook_Affiliate', 'Diversity_Affiliate','Plant_Ind'];
    subEntityColumns: any = [{'label':'Code'},{'label':'Name'},{'label':'Databook Affiliate'},{'label':'Diversity Affiliate'},{'label':'Auto Mfr Plant'}];
    dataCategoryKeys: string[] = ['DataCategory', 'InputMethod','ReportingFrequency', 'Report_Production_Employee'];
    dataCategoryColumns: any = [{'label':'Name'},{'label':'Input Method'},{'label':'Reporting Frequency'},{'label':'Report Production Emp'}];
    dataproviderSource = new MatTableDataSource();
    locationAssignmentSource = new MatTableDataSource();    
    subEntitySource = new MatTableDataSource();
    dataCategorySource = new MatTableDataSource();
    locationAssignmentPageLength: number;
    locationAssignmentPageSize: number = 5;
    locationAssignmentDataLength: number;
    awsToken:any;
    @ViewChild(MatSort) locationAssignmentSort: MatSort;
    @ViewChild('locationAssignmentPaginatorRef') locationAssignmentPaginator: MatPaginator;
    @ViewChild('addAdminRef') addAdminForm;
    @ViewChild('addCompanyRef') addCompanyForm;
    @ViewChild('addSectionRef') addSectionForm;
    @ViewChild('addContentRef') addContentForm;
    @ViewChild('addEconomicRef') addEconomicForm;
    @ViewChild('subEntityAssignmentFormRef') subEntityAssignmentFormRef;
    constructor(
        private dialogRef: MatDialogRef<AdminDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data : any,
        private frmBuilder: FormBuilder,
        private ApiService: ApiService,
        private route: ActivatedRoute) {
            this.dialogRef.disableClose = true;  
            this.content = data.dialogConfig.data;
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
    ngOnInit() {
        if(this.content.page == 'add-admin') {
            this.addAdmin = this.frmBuilder.group({
                firstname:[null,Validators.required],
                lastname:[null,Validators.required],
                emailaddress:[null,Validators.email],
                role:[null,Validators.required],
                phone:[null,[Validators.required, CustomValidator.phoneValidator]]
            });
        }
        if(this.content.page == 'edit-admin'){
            this.addAdmin = this.frmBuilder.group({
                firstname:[this.content.obj.firstname,Validators.required],
                lastname:[this.content.obj.lastname,Validators.required],
                emailaddress:[this.content.obj.emailaddress,Validators.email],
                role:[this.content.obj.roleId,Validators.required],
                phone:[this.content.obj.phone,[Validators.required, CustomValidator.phoneValidator]],
                id:[this.content.obj.id]
            });
        }
        if(this.content.page == 'add-section') {
            this.addSection = this.frmBuilder.group({
                sectionName: [null, Validators.required],           
                firstName:[this.currentUser.firstName],
                lastName:[this.currentUser.lastName],
                email:[this.currentUser.emailAddress],
            });                  
        }
        if(this.content.page == 'edit-section') {
            this.addSection = this.frmBuilder.group({
                sectionName:[this.content.obj.sectionName,Validators.required],                              
                firstName:[this.currentUser.firstName],
                lastName:[this.currentUser.lastName],
                email:[this.currentUser.emailAddress],
                sectionId:[this.content.obj.SECTION_ID]
            });                  
        }        
        if(this.content.page == 'add-content') {
            this.addContent = this.frmBuilder.group({
                contentName:[null,Validators.required],
                reportName:[null,Validators.required],
                linkName:[null,Validators.required],
                filePath:[null,Validators.required],                               
                firstName:[this.currentUser.firstName],
                lastName:[this.currentUser.lastName],
                email:[this.currentUser.emailAddress],
                sectionId:[this.content.sectionId],
                etag:[],
                bucketName:[],
                key:[]
            });
            this.getS3Token();    
                const subscription = interval(1800000)
                .subscribe(()=>{ 
                    this.getS3Token();
                });
        }
        if(this.content.page == 'edit-content') {
            this.addContent = this.frmBuilder.group({
                contentName:[this.content.obj.contentName,Validators.required],
                reportName:[this.content.obj.REPORT_NAME,Validators.required],
                linkName:[this.content.obj.LINK_NAME,Validators.required],
                filePath:[this.content.obj.FILE_PATH,Validators.required],                
                firstName:[this.currentUser.firstName],
                lastName:[this.currentUser.lastName],
                email:[this.currentUser.emailAddress],
                sectionId:[this.content.sectionId],
                id:[this.content.obj.ID],
                etag:[this.content.obj.ETAG],
                bucketName:[this.content.obj.BUCKETNAME],
                key:[this.content.obj.KEY]
            });
        this.existingReportName  = this.content.obj.FILE_PATH;
        this.getS3Token();     
            const subscription = interval(1800000)
                .subscribe(()=>{ 
                    this.getS3Token();
                });
        }
        if(this.content.page == 'add-company') {
            this.addCompany = this.frmBuilder.group({
                companyName:[null,Validators.required],
                entityCode:[null,Validators.required],
                subEntityCode:[null,Validators.required],
                firstName:[this.currentUser.firstName],
                lastName:[this.currentUser.lastName],
                email:[this.currentUser.emailAddress]
            });
            if(this.entityList == undefined || this.entityList== null){
            this.ApiService.getEntity().subscribe( result => {                
                this.entityList = result.entity;
                this.subEntityList = result.subEntity;
                },
                error => {
                    this.errorDisplay(error);
                    this.toggleMessage('error');
                });
            }
        }
        if(this.content.page == 'add-economic') {
            this.currentYear =  new Date().getFullYear();
            this.yearList = this.ApiService.getYears(this.currentYear,5); 
            this.addEconomic = this.frmBuilder.group({
                title: [null, Validators.required],           
                uscount: [null, Validators.pattern("^[0-9]*$")],
                nacount: [null, Validators.pattern("^[0-9]*$")],
                year: [this.currentYear, Validators.required],
                firstName:[this.currentUser.firstName],
                lastName:[this.currentUser.lastName],
                email:[this.currentUser.emailAddress]
            });
        }
        if(this.content.page == 'edit-economic') {
            this.currentYear =  new Date().getFullYear();
            this.yearList = this.ApiService.getYears(this.currentYear,5); 
            this.addEconomic = this.frmBuilder.group({
                title: [this.content.obj.DETAIL, Validators.required],           
                uscount: [this.content.obj.USCOUNT, Validators.pattern("^[0-9]*$")],
                nacount: [this.content.obj.NACOUNT, Validators.pattern("^[0-9]*$")],
                year: [this.content.obj.YEAR, Validators.required],
                id:[this.content.obj.ID],
                firstName:[this.currentUser.firstName],
                lastName:[this.currentUser.lastName],
                email:[this.currentUser.emailAddress]
            });
        }
        if(this.content.page == 'add-assignment-subentity') {
            this.subEntityAssignmentForm = this.frmBuilder.group({
                entityId:[null],
                auto_mfr_plant:[false],
                databook_affiliate:[null,Validators.required],
                diversity_affiliate:[null,Validators.required],
                statusNotes:[null,Validators.required],                
                subEntityId:[this.content.subEntityObj.SubEntityCode],
                entityCode:[this.content.subEntityObj.SubEntityShort],
                entityShort:[this.content.subEntityObj.EntityShort],
                entityName:[this.content.subEntityObj.SubEntityLong],                
                name:[this.currentUser.firstName+' '+this.currentUser.lastName],
                email:[this.currentUser.emailAddress]
              });
        }
        if(this.content.page == 'edit-assignment-subentity') {
            var checked = false;
            if(this.content.assignmentObj.Auto_Mfr_Plant == "Yes"){
                checked = true;
            }
            this.subEntityAssignmentForm = this.frmBuilder.group({
                entityId:[this.content.assignmentObj.EntityCode],
                auto_mfr_plant:[checked],
                databook_affiliate:[this.content.assignmentObj.Databook_Affiliate,Validators.required],
                diversity_affiliate:[this.content.assignmentObj.Diversity_Affiliate,Validators.required],
                statusNotes:[this.content.assignmentObj.Status_Note_Txt,Validators.required],                
                subEntityId:[this.content.assignmentObj.SubEntityCode],
                entityCode:[this.content.assignmentObj.SubEntityShort],
                entityShort:[this.content.subEntityObj.EntityShort],
                entityName:[this.content.assignmentObj.SubEntityLong],                
                name:[this.currentUser.firstName+' '+this.currentUser.lastName],
                email:[this.currentUser.emailAddress]
              });
        }
        if(this.content.page == 'deactivate-category') {
           var status = 'activate';
            if(this.content.status == 'A') {
                status = 'deactivate';
                this.getDataproviders(status,this.content.categoryId,this.content.obj.EntityCode);
                this.getLocationAssignments(status,this.content.categoryId,this.content.obj.EntityCode);
           }
           this.categoryStatusForm = this.frmBuilder.group({
            entityCode:[this.content.obj.EntityCode],
            categoryId:[this.content.obj.DataCategoryKey],
            entityShort:[this.content.obj.EntityShort],
            dataCategory:[this.content.obj.DataCategory],
            status:[this.content.status],                
            name:[this.currentUser.firstName+' '+this.currentUser.lastName],
            email:[this.currentUser.emailAddress]
          });            
        }
        if(this.content.page == 'deactivate-entity') {
            var status = 'activate';
             if(this.content.status == 'Active') {
                 status = 'deactivate';
                 this.getDataprovidersEntity(status,this.content.obj.EntityCode);
                 this.getLocationAssignmentsEntity(status,this.content.obj.EntityCode);
            }            
            this.entityStatusForm = this.frmBuilder.group({
             entityCode:[this.content.obj.EntityCode],
             entityShort:[this.content.obj.EntityShort],
             status:[this.content.status],                
             name:[this.currentUser.firstName+' '+this.currentUser.lastName],
             email:[this.currentUser.emailAddress]
           });            
         }
         if(this.content.page == 'deactivate-subentity') {
            var status = 'activate';
            var subEntityCode;
            var subEntityShort;
             if(this.content.status == 'Active') {
                 status = 'deactivate';                 
                 this.getLocationAssignmentsSubentity(status,this.content.obj.SubEntityCode);
            }            
            if(this.content.type == 'all'){
                subEntityCode = this.content.obj.SubEntityCode;
                subEntityShort = this.content.obj.SubEntityShort;
            } else {
                subEntityCode = this.content.element.EntityCode;
                subEntityShort = this.content.element.SubEntityShort;
            }
            this.subEntityStatusForm = this.frmBuilder.group({
             subEntityCode:[subEntityCode],
             subEntityShort:[subEntityShort],
             status:[this.content.status],
             type:[this.content.type],                
             name:[this.currentUser.firstName+' '+this.currentUser.lastName],
             email:[this.currentUser.emailAddress]
           });            
         }

    } 

    get firstname() { return this.addAdmin.get('firstname');}
    get lastname() { return this.addAdmin.get('lastname');}
    get emailaddress() { return this.addAdmin.get('emailaddress');}
    get role() { return this.addAdmin.get('role');}
    get phone() { return this.addAdmin.get('phone');}

    get companyName() { return this.addCompany.get('companyName');}
    get entityCode() { return this.addCompany.get('entityCode');}
    get subEntityCode() { return this.addCompany.get('subEntityCode');}   
    
    get sectionName() { return this.addSection.get('sectionName'); }
    

    onSelectEntity(event) {
        this.subEntity = this.ApiService.getObjects(this.subEntityList,event.value);       
     }
    onSubmitAddAdmin(){
        if(!this.addAdmin.valid)
		return;
        this.ApiService.addAdmin(this.addAdmin.value)
	     .subscribe( result => {
             if(result.error) {
                this.errorBlock = true;
                this.errorMsg = result.response;
                this.ApiService.showError('Failure!',result.response);
                this.toggleMessage('error');
             } else {
                this.ApiService.showSuccess('Success!',result.response);
                this.closeDialog(true);
                this.addAdminForm.resetForm();
             }            			
			},
            error => {
                this.errorDisplay(error);
                //this.ApiService.showError('Failure!');
                this.toggleMessage('error');				
			});  
    }
    removeAdmin(action){
        this.ApiService.deleteAdmin(this.content.adminId).subscribe(
          data => {
            this.ApiService.showSuccess('Success!',data.Success);
            this.closeDialog(action);
          },
          error => {
            this.errorDisplay(error);
            this.toggleMessage('error');
         });
      }
      addFiles() {
        this.file.nativeElement.click();
      }
    onFilesAdded() {  
        const files: { [key: string]: File } = this.file.nativeElement.files;              
        for (let key in files) {
          if (!isNaN(parseInt(key))) {
            var fileSize = files[key].size;            
             if(fileSize > 10486000) {
               this.uploadError = true;
               this.uploadErrorMsg = environment.maxFileuploadErrorMsg;
               return false;
             } else {
              this.files.add(files[key]);
             }
          }
        }
        this.closeDialogUpload();
      }
    async closeDialogUpload() {        
        if(!this.files.size) {
          return false;  
        }    
        this.uploading = true;   
        if(this.awsToken == null || this.awsToken == undefined) {
            this.getS3Token();      
          }
        // start the upload and save the progress map
        this.progress = await this.ApiService.uploadReport(this.files,this.content.sectionId,this.awsToken);
        if(this.progress.ETag){
        this.addContent.patchValue({
            filePath:this.progress.Location,
            etag:this.progress.ETag,
            bucketName:this.progress.Bucket,
            key:this.progress.Key
        })    
        this.uploadSuccess = true;
        this.uploading = false;        
        } else {
          this.uploadError = true;
          this.uploading = false;
          this.uploadErrorMsg = "Oops! There was an error uploading your file, Please close popup window and try again.";
        }             
      }
      onSubmitAddContent() {
        if(!this.addContent.valid)
        return;
        if(this.content.page == 'add-content'){
            this.ApiService.createContent(this.addContent.value)
            .subscribe( result => {
               this.closeDialog(true);
               this.addContentForm.resetForm();			
               },
               error => {	
                this.errorDisplay(error);
                this.toggleMessage('error');	
               }); 
        }
        if(this.content.page == 'edit-content'){
            this.ApiService.updateContent(this.addContent.value)
            .subscribe( result => {
               this.closeDialog(true);
               this.addContentForm.resetForm();			
               },
               error => {
                this.errorDisplay(error);
                this.toggleMessage('error');		
               });  
        }
        
        } 
        removeContent(action){
            this.ApiService.deleteContent(this.content.contentId).subscribe(
              data => {
                this.closeDialog(action);
              },
              error => {
                this.errorDisplay(error);
                this.toggleMessage('error');
             });
          }
          onSubmitAddSection() {
            if(!this.addSection.valid)
            return;
            if(this.content.page == 'add-section'){
                this.ApiService.createSection(this.addSection.value)
                .subscribe( result => {
                   this.closeDialog(true);
                   this.addSectionForm.resetForm();			
                   },
                   error => {
                    this.errorDisplay(error);
                    this.toggleMessage('error');		
                   });
            }
            if(this.content.page == 'edit-section'){
                this.ApiService.updateSection(this.addSection.value)
                .subscribe( result => {
                   this.closeDialog(true);
                   this.addSectionForm.resetForm();			
                   },
                   error => {
                    this.errorDisplay(error);
                    this.toggleMessage('error');		
                   });
            }
            
            } 
            removeSection(action){
                this.ApiService.deleteSection(this.content.sectionId).subscribe(
                  data => {
                    this.closeDialog(action);
                  },
                  error => {
                    this.errorDisplay(error);
                    this.toggleMessage('error');
                 });
              }
      onSubmitAddCompany(){
        if(!this.addCompany.valid)
		return;
        this.ApiService.addCompany(this.addCompany.value)
	     .subscribe( result => {
            this.closeDialog(true);
            this.addCompanyForm.resetForm();			
			},
            error => {
                this.errorDisplay(error);
                this.toggleMessage('error');				
			});  
    }
    removeCompany(action){
        this.ApiService.deleteCompany(this.content.companyId).subscribe(
          data => {
            this.closeDialog(action);
          },
          error => {
            this.errorDisplay(error);
            this.toggleMessage('error');
         });
      }
    removeProvider(action){
        this.ApiService.deleteDataprovider(this.content.providerId).subscribe(
            data => {
              this.closeDialog(action);
            },
            error => {
                this.errorDisplay(error);
                    this.toggleMessage('error');
           }); 
    }
    removeDatacategory(action){
        this.ApiService.deleteDataproviderCategory(this.content.categoryId).subscribe(
            data => {
              this.closeDialog(action);
            },
            error => {
                this.errorDisplay(error);
                this.toggleMessage('error');
           }); 
    }
    closeReportingPeriod(action){
        var data = {"field":this.content.obj[0].ReportingPeriod,
                "reportingperiodkey":this.content.obj[0].ReportingPeriodKey,
                "username":this.currentUser.firstName +" "+ this.currentUser.lastName,
                "email":this.currentUser.emailAddress };
        this.ApiService.closeReportingperiod(data).subscribe(
            data => {
              this.closeDialog(action);
            },
            error => {
                this.errorDisplay(error);
                this.toggleMessage('error');
           });
    }
    updateExchangeRates(action){
        this.ApiService.updateExchangeRates(this.content.obj[0].ReportingPeriodKey).subscribe(
            data => {
              this.closeDialog(action);
            },
            error => {
                this.errorDisplay(error);
                this.toggleMessage('error');
           });
    }
    onSubmitAddEconomic(){
        if(!this.addEconomic.valid)
        return;
        if(this.content.page == 'add-economic'){
            this.ApiService.createEconomicImpactData(this.addEconomic.value)
            .subscribe( result => {
               this.closeDialog(true);
               this.addEconomicForm.resetForm();			
               },
               error => {
                this.errorDisplay(error);
                this.toggleMessage('error');				
               });
        }
        if(this.content.page == 'edit-economic'){
            this.ApiService.updateEconomicImpactData(this.addEconomic.value)
            .subscribe( result => {
               this.closeDialog(true);
               this.addEconomicForm.resetForm();			
               },
               error => {	
                this.errorDisplay(error);
                this.toggleMessage('error');			
               });
        }
          
    }
    onSubmitSubEntityAssignment () {
        if(!this.subEntityAssignmentForm.valid)
        return;
        if(this.content.page == 'add-assignment-subentity'){
            this.ApiService.addSubEntityAssignment(this.subEntityAssignmentForm.value)
            .subscribe( result => {
               this.closeDialog(true);
               this.subEntityAssignmentFormRef.resetForm();			
               },
               error => {
                this.errorDisplay(error);
                this.toggleMessage('error');				
               });
        }
        if(this.content.page == 'edit-assignment-subentity'){
            this.ApiService.editSubEntityAssignment(this.subEntityAssignmentForm.value)
            .subscribe( result => {
               this.closeDialog(true);
               this.subEntityAssignmentFormRef.resetForm();			
               },
               error => {
                this.errorDisplay(error);
                this.toggleMessage('error');				
               });
        }  
    }
    getDataproviders(status,categoryId,entityCode){
        this.ApiService.getDataProviderStatusCategory(status,categoryId,entityCode).subscribe(
          data => {
            this.setDataProviderSource(data);		
          },
          error => {
            this.errorDisplay(error);
            this.toggleMessage('error');
         });
      }
      getLocationAssignments(status,categoryId,entityCode){
        this.ApiService.getLocationAssignmentStatusCategory(status,categoryId,entityCode).subscribe(
          data => {
            this.setLocationAssignmentSource(data);
          },
          error => {
            this.errorDisplay(error);
            this.toggleMessage('error');
         });
      }
      confirmCategoryStatus() {
        this.ApiService.statusChangeCategory(this.categoryStatusForm.value)
            .subscribe( result => {
               this.closeDialog(true);               			
               },
               error => {	
                this.errorDisplay(error);
                this.toggleMessage('error');			
               });  
      }
      getDataprovidersEntity(status,entityCode){
        this.ApiService.getDataProviderStatusEntity(status,entityCode).subscribe(
          data => {
                this.setDataProviderSource(data);
                this.setSubentitySource(data);		
          },
          error => {
            this.errorDisplay(error);
            this.toggleMessage('error');
         });
      }
      getLocationAssignmentsEntity(status,entityCode){
        this.ApiService.getLocationAssignmentStatusEntity(status,entityCode).subscribe(
          data => {
            this.setLocationAssignmentSource(data);
          },
          error => {
            this.errorDisplay(error);
            this.toggleMessage('error');
         });
      }
      confirmEntityStatus() {
        this.ApiService.statusChangeEntity(this.entityStatusForm.value)
            .subscribe( result => {
               this.closeDialog(true);               			
               },
               error => {	
                this.errorDisplay(error);
                this.toggleMessage('error');			
               });  
      }
      getLocationAssignmentsSubentity(status,subEntityCode){
        this.ApiService.getLocationAssignmentStatusSubentity(status,subEntityCode).subscribe(
          data => {
            this.setLocationAssignmentSource(data);
          },
          error => {
            this.errorDisplay(error);
            this.toggleMessage('error');
         });
      }
      confirmSubentityStatus() {
        this.ApiService.statusChangeSubentity(this.subEntityStatusForm.value)
            .subscribe( result => {
               this.closeDialog(true);               			
               },
               error => {
                this.errorDisplay(error);
                this.toggleMessage('error');				
               });  
      }
      setDataProviderSource(data){
        this.dataproviderSource.data = data.dataProviders;
      }
      setSubentitySource(data){
          this.subEntitySource.data = data.subEntity;
          this.dataCategorySource.data = data.dataCategory;
      }
      setLocationAssignmentSource(data) {
        this.locationAssignmentSource.data = data.locationAssignments;
        this.locationAssignmentDataLength = data.locationAssignments.length;
        this.locationAssignmentPageLength = Math.ceil(this.locationAssignmentDataLength / this.locationAssignmentPageSize);
        setTimeout(() => this.locationAssignmentSource.paginator = this.locationAssignmentPaginator);
        this.locationAssignmentSource.sort = this.locationAssignmentSort;
      }
    closeDialog(status){
        this.dialogRef.close(status);
    }
    errorDisplay(error) {
        this.errorBlock = true;
        if(error.status == "500"){
          this.errorMsg = environment.errorMsg500;
        } else if(error.status == "400"){
          this.errorMsg = error.error.Error;
        } else {
          this.errorMsg = environment.errorMsg502;
        }
        this.toggleMessage('error');
      }
      toggleMessage(type){
        setTimeout(() => {
         if(type == 'success'){
          this.successBlock = false;
         } else {
          this.errorBlock = false;
         }      
      }, 5000);
      }
      getS3Token() {
        this.ApiService.getSTSToken()
        .subscribe(data => {
          this.awsToken = data;
        },
        error=>{
        });          
    }
}