import { Component, OnInit } from '@angular/core';
import * as pbi from 'powerbi-client';
import * as models from 'powerbi-models';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit{
	permissions = models.Permissions.All;
	layOut:any = models.LayoutType.Custom;
	reportWidth;
	reportHeight;
	reportPrint:any;
	isIEOrEdge:boolean;	
	constructor(private ApiService: ApiService) { }
	ngOnInit() {	
		console.log(window.innerHeight);
		console.log(window.innerWidth);
		this.reportWidth = window.innerWidth - 90;
		this.reportHeight = window.innerHeight - 30;
		this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);	
	} 
	ngAfterViewInit() { 
		var authToken = localStorage.getItem('authToken');
		if(authToken && authToken != null) {
			this.getReports();
		} else {
			this.ApiService.getAuthToken().subscribe(
				data => {
					localStorage.setItem('authToken',data.accesstoken);
					this.getReports();
				});			
		}		
	}
	/** -----------------------Power BI Report embed code starts ----------------------------**/
	getReports() {
	this.ApiService.getReportsData(1).subscribe(
	      data => { 
			// Report's Secured Token			
			const accessToken = data.token;			
			// Embed URL 
			let embedUrl = data.embedUrl;			
			// Report ID
			let embedReportId = data.reportId;			
			// Configuration used to describe the what and how to embed.	
			let setting:any = {
			filterPaneEnabled: true,
			navContentPaneEnabled: true
			//pageView: 'fitToWidth',
			//layoutType: this.layOut
			/*customLayout: {		
				/*pageSize: {
					type: models.PageSizeType.Custom,
					width: this.reportWidth,
					height: this.reportHeight
				},	*/			
				/*displayOption: models.DisplayOption.ActualSize
			}	*/	
			};	
    const config = {
        type: 'report',		
        tokenType:models.TokenType.Embed,
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: embedReportId,
		permissions:this.permissions,
		settings:setting       
    };
	
    // Grab the reference to the div HTML element that will host the report.
    let reportContainer = <HTMLElement>document.getElementById('reportContainer');

    // Embed the report and display it within the div container.
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
	let report = powerbi.embed(reportContainer, config);
	this.reportPrint = powerbi.get(reportContainer);
	
	//report.fullscreen();
    // Report.off removes a given event handler if it exists.
    report.off("loaded");

    // Report.on will add an event handler which prints to Log window.
    report.on("loaded", function() {
        console.log("Loaded");
    });
	      }
	  );
	}
	/** -----------------------Power BI Report embed code ends----------------------------**/
	print()
	{
		this.reportPrint.print().catch(error => {
		});
	}
}