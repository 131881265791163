import { Component, OnInit, ViewChild,AfterViewInit,ViewEncapsulation} from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table'; 
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from '../../services/api.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogComponent } from '../../dialog.component';
import { MessageComponent } from '../../message.component';
import { environment } from '../../../environments/environment';
//imported for template download
import {ExcelService} from './excel.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-fileUpload',
  templateUrl: './fileUpload.component.html',
  styleUrls: ['./fileUpload.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent implements OnInit,AfterViewInit{	
  displayedColumns: string[] = ['id','columnName'];

  errordisplayedColumns: string[];
  errordisplayedLabels: any;

  dataSource = new MatTableDataSource();
  pageLength: number;
  pageSize: number = 10;
  dataLength: number;
  errordataSource = new MatTableDataSource();
  errorpageLength: number;
  errorpageSize: number = 10;
  errordataLength: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) errorpaginator: MatPaginator;
  @ViewChild(MatSort) errorsort: MatSort;
  dataCategoryId:number;
  entityId:number;
  reportingPeriodData;
  uploadFileColumn;
  fileStatus:any;
  errorData:any;
  approverForm:FormGroup;
  approverBlock: boolean = true;
  uploadBlock: boolean = false;
  referenceBlock: boolean = false;
  helpTextBlock: boolean = false;
  fileStatusBlock: boolean = false;
  errorBlock:boolean;
  fileStatusError:boolean;
  errorMsg:string;
  successBlock:boolean;
  successMsg:string;
  currentUser: any;
  approverDetails: any;
  helpText:string;
  fieldNames:any;
  csvData:any;
  constructor(public dialog: MatDialog,private ApiService: ApiService,private route: ActivatedRoute,
    private frmBuilder:FormBuilder, private router: Router, private excelService:ExcelService) {
      this.dataCategoryId = this.route.snapshot.params.dataCategoryId;      
      this.entityId = this.route.snapshot.params.entityId;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.ApiService.getColumns(this.dataCategoryId,this.entityId).subscribe(
        data => {
        this.dataSource.data = data.uploadFileColumn;
        this.dataLength = data.uploadFileColumn.length;
        this.pageLength = Math.ceil(this.dataLength / this.pageSize);                     
        this.reportingPeriodData = data.reportingPeriodData[0];
        this.approverDetails = data.approver;
        this.helpText = data.helpText;
        if(data.approver.ApproverTitle != undefined) {
          this.setApproverData(data.approver);
        }
        });    
  }
  
  
  data: any;
   
     Payroll_data: any = [{
       'Reporting Entity' : '',
       'Work Location': '',
       'Reporting Sub Entity': '',
       'Production Wages' : '',
       'Non-Production Wages' : '',
       'Currency Code' : ''
       }
      ];
      Investment_data: any = [{
        'Reporting Entity': '',
        'Work Location': '',
        'Reporting Sub Entity': '',
        'Fixed Asset Amount' : '',
        'Currency Code' : ''
        }];
        Employee_Data: any = [{
          'Reporting Entity': '',
          'Work Location': '',
          'Reporting Sub Entity':'',
          'Employee Type' : '',
          'Employee Status' : '',
          'Full Time / Part Time' : '',
          'Employee Address Line 1' : '',
          'Employee Address Line 2' : '',
          'Employee City' : '',
          'Employee State' : '',
          'Employee Zip Code' : '',
          'Employee Country' : '',
          'Employee Province' : '',
          'Employee Postal Code' : ''
          }];
   async ngOnInit() {
    this.approverForm = this.frmBuilder.group({
      approverName: [null, Validators.required],
      approverTitle: [null, Validators.required],			
      statusNotes: [null, Validators.required],
      approverEmail:[null, Validators.email],
      approverDate:[formatDate(new Date(),'yyyy-MM-dd','en'),Validators.required],
      datacategorykey:[],
      entityCode:[],
      reportingPeriodKey:[],      
      email:[],
      name:[],
    })
    var body =
    {
      "DataCategoryKey" : this.route.snapshot.params.dataCategoryId,
      "EntityCode" : this.route.snapshot.params.entityId
    }

    this.ApiService.getCsvDetails(body).subscribe(
    data => {
      this.fieldNames = data[0].FieldName;
      this.csvData = data[0].CsvDetails;
    }) 
  }

  get approverName() { return this.approverForm.get('approverName'); }
  get approverTitle() { return this.approverForm.get('approverTilte'); }
  get approverDate() { return this.approverForm.get('approverDate'); }
  get statusNotes() { return this.approverForm.get('statusNotes'); }
  get approverEmail() { return this.approverForm.get('approverEmail'); }

  ngAfterViewInit() {	
  }
  setApproverData(approver) {
    this.approverForm.patchValue({
      approverName: approver.ApproverName,
      approverTitle: approver.ApproverTitle,
      approverEmail:approver.ApproverEmail,
      approverDate:formatDate(approver.ApproverDate,'yyyy-MM-dd','en'),
      statusNotes:approver.Notes
    });
  }
  onSubmitApprover() {
    if(!this.approverForm.valid)
    return;
    this.requiredPatchValue();
    this.ApiService.saveApproverManualEntry(this.approverForm.value)
        .subscribe( result => {
          this.successBlock = true;
          this.successMsg = result.Message;
          this.toggleMessage('success');            
       },
       error => {         
        this.errorDisplay(error);				
       });
  }
  requiredPatchValue() {
    this.approverForm.patchValue({
      reportingPeriodKey:this.reportingPeriodData.ReportingPeriodKey,
      datacategorykey:this.dataCategoryId,
      entityCode:this.entityId,
      email:this.currentUser.emailAddress,
      name:this.currentUser.firstName+' '+this.currentUser.lastName
    });
  }
  back(type){
    if(type == 'admin') {
     this.router.navigate(['databook/admin',1010]);
    } else if(type == 'provider') {
      this.router.navigate(['databook/DataProviderDashboard',1018]);
    }
  }
  tabs($event,type) { 
    var temp = $event.currentTarget.parentNode.childNodes;    
    for(var i =0;i<temp.length;i++) {      
        if(temp[i].classList.contains('active')) {
          temp[i].classList.remove('active');
        }
    }
  $event.currentTarget.classList.add('active');
    this.approverBlock = false;
    this.uploadBlock = false;
    this.referenceBlock = false;
    this.helpTextBlock = false;
    this.fileStatusBlock = false;
    if(type == 1) {
      this.approverBlock = true;   
    } else if(type == 2) {
      this.uploadBlock = true;
      this.getFileStatus();     
    } else if(type == 3) {
      this.referenceBlock = true;
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    } else if(type == 4) {
      this.helpTextBlock = true;
    } else if(type == 5) {
      this.fileStatusBlock = true;
      this.getFileStatus();
    } else {
      this.approverBlock = true; 
    }
  }
  getFileStatus() {
    if(this.dataCategoryId == 5){
      this.errordisplayedColumns = ['EmployeeDetailErrorFileName','ErrorFieldName','ErrorDescription','Entity',
      'SubEntity','CreatedBy','ProcessMonth','ProcessYear','EmployeeStatus','EmployeeType','PartTimeFlag',
      'WorkLocation','WorkerCategory','AddressLine1','AddressLine2','City','Company','Country','PostalCode',
      'Province','State','StatusFlag','ZipCode'];
      this.errordisplayedLabels = [{'label':'File Name'},{'label':'Field Name'},{'label':'Error Description'},
      {'label':'Entity'},{'label':'Sub Entity'},{'label':'Created By'},{'label':'Process Month'},{'label':'Process Year'},
      {'label':'Employee Status'},{'label':'Employee Type'},{'label':'Company'},{'label':'Status Flag'},{'label':'Part Time Flag'}
      ,{'label':'Work Location'},{'label':'Worker Category'},{'label':'Address Line1'},{'label':'Address Line2'},{'label':'City'},
      {'label':'Country'},{'label':'Postal Code'},{'label':'Province'},{'label':'State'},{'label':'ZipCode'}];
    } else if(this.dataCategoryId == 6) {
      this.errordisplayedColumns = ['INVESTMENT_ERR_FILE_NAME','ERR_FIELD_NAME','ERR_DESC','REP_ENTITY',
      'REP_SUB_ENTITY','CREATED_BY','CREATED_DT','PROCESS_YEAR','WORK_LOC'];
      this.errordisplayedLabels = [{'label':'File Name'},{'label':'Field Name'},{'label':'Error Description'},
      {'label':'Entity'},{'label':'Sub Entity'},{'label':'Created By'},{'label':'Created Date'},{'label':'Process Year'},
      {'label':'Work Location'}];
    }
    this.ApiService.getfileStatus(this.dataCategoryId,this.entityId).subscribe(
      data => {
        this.fileStatus = data;      
        if(data.errorData) {
          this.errordataSource.data = data.errorData;
          this.errordataLength = data.errorData.length;
          this.errorpageLength = Math.ceil(this.errordataLength / this.errorpageSize);
          setTimeout(() => this.errordataSource.paginator = this.errorpaginator);
        }
      },error => {    
				this.errorDisplay(error);
      });
  }
  errorDisplay(error) {
    this.errorBlock = true;
    if(error.status =="500") {
      this.errorMsg = environment.errorMsg500;
    } else if(error.status =="400"){
      this.errorMsg = error.error.Message;
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type) {
    setTimeout(() => {
     if(type == 'success') {
      this.successBlock = false;
     } else {
      this.errorBlock = false;
     }      
    },5000);
  }
  public openUploadDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {        
		title: 'upload',
    page: "upload",
    dataCategoryId:this.dataCategoryId,
    entityId:this.entityId
    };
  const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:MessageComponent},width: '50%', height: '50%'});
  dialogRef.afterClosed().subscribe(
    data =>{ 
         this.getFileStatus();  
     });	
  }
  //=============Added for file template download======================//
   downloadFileInvestment1():void {
    this.excelService.downloadFileInvestment1(this.csvData, 'template',this.fieldNames);
 }
  PayrollexportAsXLSX():void {
   //this.excelService.exportAsExcelFile(this.Payroll_data, 'payroll');
   this.excelService.downloadFilePayroll(this.Payroll_data, 'payroll');
 }
InvestmentexportAsXLSX():void {
  //this.excelService.exportAsExcelFile(this.Investment_data, 'investment');
  this.excelService.downloadFileInvestment(this.Investment_data, 'investment');
}
EmployeeexportAsXLSX():void {
  //this.excelService.exportAsExcelFile(this.Employee_Data, 'employee');
  this.excelService.downloadFileEmployee(this.Employee_Data, 'employee');
}
}
