// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  getSTSTokenBaseURL:'https://n3zvxtw3mb.execute-api.us-east-1.amazonaws.com',
  getSTSTokenPath :'dev/sts',
  AssumeRole:'AssumeRole',
  authTokenBaseURL :'https://4azvgarli7.execute-api.us-east-1.amazonaws.com',
  authTokenPath : 'dev/auth',
  generateToken:'generateToken',
  getS3FileBaseURL:'https://aocd5amqvf.execute-api.us-east-1.amazonaws.com',
  getS3FilePath:'dev/signedurl',
  getS3FileURL:'getURL',
  employeeDetailBucket:'dceir-fileupload-dev',
  investmentBucket:'dceir-investment-dev',
  employeeDetailFolder:'dceir-emp-dtl-dev',
  payrollFolder: 'payroll-etl',
  payrollBucket: 'dceir-payroll-dev',
  investmentFolder:'investment',
  dealerFolder: 'dealerdtl-etl',
  dealerBucket: 'dceir-dealerdtl-dev',
  reportsBucket:'dceir-glossaryreports-dev',
  reportsFolder:'monthlyreports',
  legislativeDataRefreshBucket:'dceir-legislativedata-dtl-dev',
  legislativeDataRefreshFolder:'legislativedata-etl',
  loginDomain:'https://login.microsoftonline.com/tmnatest.onmicrosoft.com/oauth2/v2.0/authorize',
  loginURLPramClientId:'f43a55fb-2ec5-4962-a9cf-bb34355a4a24',
  loginURLPramResponseType:'id_token+token',
  loginURLPramScope:'openid',
  loginURLPramResponseMode:'fragment',
  loginURLPramState:'12345',
  loginURLPramNonce:'678910',
  logoutURL:'https://login.microsoftonline.com/common/oauth2/logout',

  errorMsg502:'<strong>Something went wrong please try again after some time</strong>',
  errorMsg500:'<strong>Error has been occurred due to following reason:</strong><ul><li>Domain name not resolvable</li><li>Origin server down</li><li>Firewall blocks request</li>',
  maxFileuploadErrorMsg:'Please upload below 10Mb file.',


  apiCommonPath:'dev/dceir',
  accessTokenBaseURL:'https://x9et0t16b8.execute-api.us-east-1.amazonaws.com',
  accessTokenName:'getEmbedUrlToken',

  refreshReport:'https://udqh160t1a.execute-api.us-east-1.amazonaws.com',
  getRefreshedReport:'getRefreshedReport',

  locationBaseURL:'https://gwoc2x3wog.execute-api.us-east-1.amazonaws.com',
  searchLocationPath:'searchLocationResult',
  saveLocationPath:'saveLocation',
  addAssignmentResponse:'addAssignmentResponse',
  addAssignment:'addAssignment',
  getLocation:'getLocationAssignments',
  getLocationFormData:'getLocationResponse',
  assignmentStatus: 'statusChangeAssignment',
  updateAssignment: 'updateAssignment',
  activateAllLocation:'activateAll',
  deactivateAllLocation:'deactivateAll',
  getCountryStateURL:'getCountryState',
  getDataCategory:'getDataCategory',
  getSEntity:'getSubEntity',

  userDetailsBaseURL:'https://hhbqk2aj2c.execute-api.us-east-1.amazonaws.com',
  getLoginResponse:'getLoginResponse',
  
  uploadDataColumnsBaseURL:'https://8ejy900cq8.execute-api.us-east-1.amazonaws.com',
  getUploadDatacolumns:'loadUploadFileColumn',
  getGlossaryDetailsColumns: 'loadGlossaryDetailColumn',
  getFileStatus:'getFileStatus',
  getDataproviderDetails:'getDataproviderDetails',
  getFileToUploadDetails:'getFileToUploadDetails',
  getCsvDetails:'getCsvDetails',

  contactBaseURL: 'https://73jil05ta4.execute-api.us-east-1.amazonaws.com',
  getContact:'getContacts',
  updateContact:'updateContact',
  addContact:'addContact',
  removeContact:'deleteContact',

  adminListBaseURL:'https://021qbkysv4.execute-api.us-east-1.amazonaws.com',
  getAdminsList:'getAllAdmins',
  deleteAdmin:'deleteAdmin',
  addAdmin:'addAdmin',
  getAllRoles:'getAllRoles',

  companyListBaseURL:'https://4fqynvsvlh.execute-api.us-east-1.amazonaws.com',
  getCompanyList:'getAllCompany',
  deleteCompany:'deleteCompany',
  addCompany:'addCompany',
  getEntity:'getEntity',

  dataProviderBaseURL:'https://cg31889961.execute-api.us-east-1.amazonaws.com',
  getDataProvider:'getAllDataproviders',
  getDataProvider1:'getAllEntityForDataprovider',
  addDataProvider:'addDataproviderProfile',
  updateDataproviderProfile:'updateDataproviderProfile',
  getExistingDataCategory:'getAllProfileForDataproviders',
  getAllAdminsDetails:'getAllAdminsDetails',
  getEntityDatacategoryDetails:'getEntityDatacategoryDetails',
  deleteDataprovider:'deleteDataprovider',
  deleteDataproviderProfile:'deleteDataproviderProfile',    

  repotingPeriodBaseURL:'https://sf3lo38g29.execute-api.us-east-1.amazonaws.com',
  getAllReportingperiods:'getAllReportingperiods',
  reopenReportingperiod:'reopenReportingperiod',
  closeReportingperiod:'closeReportingperiod',
  getDatacategories:'getDatacategories',
  openNewReportingperiod:'openNewReportingperiod',
  exchangeRatesBaseURL:"https://yasstnig0f.execute-api.us-east-1.amazonaws.com",
  updateExchangeRates:'updateExchangeRates',

  manualEntryBaseURL : 'https://o568hsvs2c.execute-api.us-east-1.amazonaws.com',  
  saveApproverManualEntry:'saveApproverManualEntry',
  saveManualEntryDetails:'saveManualEntryDetails',
  getEmployeeSummaryManualEntry:'getEmployeeSummaryManualEntry',
  getPensionManualEntry:'getPensionManualEntry',
  getContractLabourManualEntry:'getContractLabourManualEntry',
  getDealerSummaryManualEntry:'getDealerSummaryManualEntry',
  getCurrencyCode:'getCurrencyCode',
  getGoodsAndPurchaseManualEntry:'getGoodsAndPurchaseManualEntry',
  getPayrollManualEntry:'getPayrollManualEntry',

  manageContentBaseURL:'https://fuojetfhe4.execute-api.us-east-1.amazonaws.com',
  getSectionList:'getAllSections',
  getAllContentList:'getAllContents',
 
  getContentList:'getContents',
  createSection:'createSection',
  createContent:'createContent',
  updateSection:'updateSection',
  updateContent:'updateContent',
  deleteSection:'deleteSection',
  deleteContent:'deleteContent',

  manageEntityBaseURL: 'https://5e6fmwi7ba.execute-api.us-east-1.amazonaws.com',
  getAllEntity:'getAllEntity',
  getAllCountry:'getAllCountry',
  addEntity:'addEntity',
  editEntity:'editEntity',
  getDataproviderEntity:'getDataProviderStatus',
  getLocationAssignmentEntity:'getLocationAssignmentStatus',
  statusChangeEntity:'statusChangeEntity',
  
  manageSubEntityBaseURL:'https://ag7yvrjgib.execute-api.us-east-1.amazonaws.com',
  getAllSubEntity:'getAllSubEntity',
  addSubEntity:'addSubEntity',
  editSubEntity:'editSubEntity',
  addSubEntityAssignment:'addAssignment',
  editSubEntityAssignment:'editAssignment',
  getEntityList:'getEntity',
  getAllSubEntityAssignment:'getAllAssignment',
  getLocationAssignmentSubentity:'getLocationAssignmentStatus',
  statusChangeSubentity:'statusChangeSubentity',

  reportsPageBaseURL:'https://izvkzwkcdj.execute-api.us-east-1.amazonaws.com',
  getReportsLinks:'getReport',

  economicImpactDataBaseURL:'https://wt09x8afmd.execute-api.us-east-1.amazonaws.com',
  getAllEconomicImpactData:'getAllEconomicImpactData',
  createEconomicImpactData:'createEconomicImpactData',
  updateEconomicImpactData:'updateEconomicImpactData',

  manageCategoryBaseURL:'https://prvplfvap8.execute-api.us-east-1.amazonaws.com',
  getAllCategory:'getAllDataCategory',
  getDataCategoryDropdown:'getDataCategory',
  addDataCategory:'addDataCategory',
  editDataCategory:'editDataCategory',
  getDataproviderCategory:'getDataProviderStatus',
  getLocationAssignmentCategory:'getLocationAssignmentStatus',
  statusChangeDataCategory:'statusChangeDataCategory',
  saveAuditScreenLogDataURL: 'saveAuditScreenLog',
  saveUserDetailURL : 'https://kpsqxm4ljj.execute-api.us-east-1.amazonaws.com'
};
