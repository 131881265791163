import { Injectable, Injector } from '@angular/core';
import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { finalize } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { interval} from "rxjs";

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  activeRequests: number = 0;
 constructor(private injector: Injector) { }
 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //const getLoginURL = '/getLoginResponse';
  const tokenURL = 'auth/generateToken';
  const mellisaURL = 'doContactVerify';
  const downloadS3URL = 'getURL';
  // Exclude interceptor for login request:
  if (request.url.search(tokenURL) === -1 && request.url.search(mellisaURL) === -1) {
    console.log(tokenURL);
    var authToken = localStorage.getItem('authToken');
    authToken = (authToken) ? authToken : '';
    request = request.clone({ headers: request.headers.set('Authorization', authToken) });
    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    /*if(request.url.search(downloadS3URL) != -1){
      console.log(downloadS3URL);
      //request = request.clone({ headers: request.headers.set('x-amz-server-side-encryption', 'AES256') });
    }*/
  } 

   return next.handle(request)
     .pipe(
       retry(1),
       finalize(() => {       
      }),
       catchError((error: HttpErrorResponse) => {        
        const toastr = this.injector.get(ToastrService);
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // client-side error
           errorMessage = `Error: ${error.error.message}`;
         } else {
           if(error.status == 400) {
            errorMessage = 'Server side error, Please try some time later';
           }
           else if(error.status == 401) { 
            errorMessage = error.message;
            if(error.error.message == 'Unauthorized'){
              errorMessage = "Your session time exipred, We will logout you from this application.";
              const subscription = interval(4000)
              .subscribe(()=>{ 
                localStorage.clear();
                window.location.href = environment.logoutURL;
              });              
            } else {
              errorMessage = 'Server side error, Please try some time later';
            }
           } else if (error.status == 400){
            errorMessage = error.error.Error;
           } else {
            errorMessage = 'Server side error, Please try some time later';
           }
         }
         toastr.error(errorMessage,'Failure');
         return throwError(errorMessage);
       })
     )
    } 
 }