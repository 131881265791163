import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'auditSplit'})

export class AuditSplitPipe implements PipeTransform {
   transform(value:string, splitText:string,type:number):string {
    var temp = value.split(splitText);
    return temp[type];
  }
}
