import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-manage-location',
	templateUrl: './manageLocation.component.html',
	styleUrls: ['./manageLocation.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class ManageLocationComponent implements OnInit, AfterViewInit {
	displayedColumns: string[] = ['action', 'Primary_Facility_Nm', 'ReportingEntityLocationCode', 'DatabookLocationCode', 'Address1', 'Address2', 'City', 'US_County', 'StateName', 'Zipcode', 'CountryName'];
	dataSource = new MatTableDataSource();
	pageLength: number;
	pageSize: number = 10;
	dataLength: number
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	searchLocation: FormGroup;
	submitData: any = null;
	getCountryData: any = {};
	countryList: any[];
	stateList: any[];
	states: any[];
	primaryFacilityOwner: any[];
	primaryFacilityType: any[];
	entityList: any[];
	dataCategoryList: any = [];
	dataCategory: any;
	errorBlock: boolean = false;
	errorMsg: string;
	successBlock: boolean;
	successMsg: boolean;
	isAssignmentScreen: boolean = false;
	@ViewChild('searchLocationRef') searchLocationForm;
	constructor(private ApiService: ApiService, private frmBuilder: FormBuilder, private activeRoute: ActivatedRoute, private router: Router) { }
	ngOnInit() {
		this.searchLocation = this.frmBuilder.group({
			entity_location_code: [''],
			entity: [''],
			data_category: [''],
			data_category_assignment_status: [''],
			primary_location_status: [''],
			company_name: [''],
			county: [''],
			country: [''],
			city: [''],
			state: [''],
			primary_facility_name: [''],
			primary_facility_type: [''],
			primary_facility_owner: ['']
		});
		this.getPrimaryFacility();

		if (!this.isAssignmentScreen) {
			this.getAssignmentScreen();
		}

	}
	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}
	get entity_location_code() { return this.searchLocation.get('entity_location_code'); }
	get entity() { return this.searchLocation.get('entity'); }
	get data_category() { return this.searchLocation.get('data_category'); }
	get data_category_assignment_status() { return this.searchLocation.get('data_category_status'); }
	get primary_location_status() { return this.searchLocation.get('primary_location_status'); }
	get company_name() { return this.searchLocation.get('company_name'); }
	get county() { return this.searchLocation.get('county'); }
	get country() { return this.searchLocation.get('country'); }
	get city() { return this.searchLocation.get('city'); }
	get state() { return this.searchLocation.get('state'); }
	get primary_facility_name() { return this.searchLocation.get('primary_facility_name'); }
	get primary_facility_type() { return this.searchLocation.get('primary_facility_type'); }
	get primary_facility_owner() { return this.searchLocation.get('primary_facility_owner'); }
	addLocation() {
		this.router.navigate(['databook/location', 1008]);
	}
	onSubmitSearchLocation() {
		this.getSearchLocation();
	}
	getAssignmentScreen() {
		this.isAssignmentScreen = true;
		this.getAllEntity();
		// this.ApiService.getAssignmentScreenData().subscribe( result => {				
		// 	this.entityList = result.entity;				
		// 	this.dataCategoryList = result.dataCategory;
		// 	},
		// 	error => {});
	}
	getSearchLocation() {
		this.ApiService.searchLocation(this.searchLocation.value).subscribe(
			data => {
				this.dataSource.data = data.searchResult;
				this.dataLength = data.searchResult.length;
				this.pageLength = Math.ceil(this.dataLength / this.pageSize);
				setTimeout(() => this.dataSource.paginator = this.paginator);
			},
			error => {
				this.errorDisplay(error);
			});
	}
	cancel() {
		this.searchLocationForm.resetForm();
	}
	onSelectEntity(event) {
		var x = event.value;
		const body = {
			"entityCode": x
		};
		this.dataCategory = this.ApiService.getDataCategory(body).subscribe(result => {
			//           console.log(result);
			this.dataCategory = result.dataCategory[0];
			this.dataCategoryList = this.dataCategory;
		},
			error => {
			});;
	}

	// selectCountry(event) {		
	// 	this.stateList = this.ApiService.getObjects(this.states,event.value);  
	// }

	getPrimaryFacility() {
		//this.getAllEntity();
		this.ApiService.getCountryState().subscribe(result => {
			this.getCountryData = result;
			this.countryList = result.country.sort();
			// this.states = result.state;
			this.primaryFacilityType = result.primaryFacilityType;
			this.primaryFacilityOwner = result.primaryFacilityOwner;


		},
			error => {

			});
	}
	getAllEntity() {
		this.ApiService.getAllEntity().subscribe(
			data => {
				console.log("data is", data);
				this.entityList = data.entity;
				console.log(this.entityList);
			},
			error => {

			});
	}
	selectCountry(event) {
		let x = event.value;
		console.log(event.value);
		const body = {
			"countryCode": x
		}
		this.ApiService.getState(body).subscribe(result => {
			this.states = result.state[0];
			this.stateList = this.states.sort((a, b) => {
				if (a.name < b.name) {
					return 1;
				}
				if (a.name > b.name) {
					return -1;
				}
				return 0;
			});
		},
			error => {
			});
	}

	editLocation(locationKey) {
		this.router.navigate(['databook/location', locationKey, 1009]);
	}
	errorDisplay(error) {
		this.errorBlock = true;
		if (error.status == "500") {
			this.errorMsg = environment.errorMsg500;
		} else if (error.status == "400") {
			this.errorMsg = error.error.Message;
		} else {
			this.errorMsg = environment.errorMsg502;
		}
		this.toggleMessage('error');
	}
	toggleMessage(type) {
		setTimeout(() => {
			if (type == 'success') {
				this.successBlock = false;
			} else {
				this.errorBlock = false;
			}
		}, 5000);
	}
}
