import { Component, OnInit, ViewChild,AfterViewInit,ViewEncapsulation} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../../dialog.component';
import { AdminDialogComponent } from '../../adminDialog.component';
import { entityDataService } from '../../services/entityData.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manageSubEntity',
  templateUrl: './manageSubEntity.component.html',
  styleUrls: ['./manageSubEntity.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ManageSubEntityComponent implements OnInit,AfterViewInit{	
  SubEntityId;
  matcher = new MyErrorStateMatcher();
  subEntityForm: FormGroup;
  entityList: any[];    
  countryList:any = [];
  subEntityObj:any;
  currentUser:any;
  successBlock:boolean = false;
  successMsg: string;
  errorBlock:boolean = false;
  errorMsg: string;  
  displayedColumns: string[] = ['edit','action','EntityShort','Databook_Affiliate','Diversity_Affiliate','Auto_Mfr_Plant','Status','Status_Note_Txt',
  'Activated_Dte','Activated_By_Profile_Key','Deactivated_Dte','Deactivated_By_Profile_Key','Created_Dte','Created_By_Profile_Key','Last_Updated_Dte','Last_Updated_By_Profile_Key'];
  assignmentColumns: any = [{'label':'Edit'},{'label':'Action'},{'label':'Entity'},{'label':'Data Affiliate'},{'label':'Diversity Affiliate'},{'label':'Auto Mfr Plant'},
  {'label':'Status'},{'label':'Status Notes'},{'label':'Activated'},{'label':'Activated By'},{'label':'Deactivated'},{'label':'Deactivated By'},
  {'label':'Created'},{'label':'Created By'},{'label':'Last Updated'},{'label':'Last Updated By'}];
	assignmentDataSource = new MatTableDataSource();
	pageLength: number;
  pageSize: number = 10;
  dataLength: number
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;	
  assignmentList:any;
  @ViewChild('addSubEntityRef') addSubEntityForm;
  
  constructor(public dialog: MatDialog,private ApiService: ApiService,private route: ActivatedRoute,
    private frmBuilder:FormBuilder, private router: Router,private entityDataService:entityDataService) {      
      this.route.params.subscribe( params => {
        this.SubEntityId = params.SubEntityId;});
      this.subEntityObj = this.entityDataService.subEntityObj;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));     
  }
  ngOnInit() { 
    if(this.SubEntityId == 0) {
      this.subEntityForm = this.frmBuilder.group({
        entityId:[null],
        entityCode:[null,Validators.required],
        entityName:[null,Validators.required],
        auto_mfr_plant:[false],
        databook_affiliate:[null,Validators.required],
        diversity_affiliate:[null,Validators.required],
        statusNotes:[null,Validators.required],
        name:[this.currentUser.firstName+' '+this.currentUser.lastName],
        email:[this.currentUser.emailAddress]
      });
    } else {
      this.subEntityForm = this.frmBuilder.group({
        entityId:[null],
        subEntityId:[null],
        entityCode:[null,Validators.required],
        entityName:[{value: null, disabled: true},Validators.required],
        auto_mfr_plant:[false],
        databook_affiliate:[null,Validators.required],
        diversity_affiliate:[null,Validators.required],
        statusNotes:[null,Validators.required],
        name:[this.currentUser.firstName+' '+this.currentUser.lastName],
        email:[this.currentUser.emailAddress]
      });
      if(this.subEntityObj) {
        this.subEntityForm.patchValue({
        subEntityId:this.subEntityObj.SubEntityCode,
        entityName:this.subEntityObj.SubEntityLong
        });
        this.getAllSubEntityAssignment(this.subEntityObj.SubEntityCode);
      } else {
        this.router.navigate(['databook/manageSubEntity',0,1021]);
      }
    }
      this.getEntityList();
  } 
  ngAfterViewInit() {  
    this.assignmentDataSource.sort = this.sort;
  }
  getEntityList() {
    this.ApiService.getEntityList().subscribe( result => {
      this.entityList = result.entity;
      },
      error => {});
  }
  getAllSubEntityAssignment(id) {
    this.ApiService.getAllSubEntityAssignment(id).subscribe( result => {
      this.assignmentList = result.Assignment;
				this.assignmentDataSource.data = result.Assignment;			
				this.dataLength = result.Assignment.length;
				this.pageLength = Math.ceil(this.dataLength / this.pageSize);
				setTimeout(() => this.assignmentDataSource.paginator = this.paginator);
      },
      error => {});
  }
  onSubmitSubEntity(){
    if(!this.subEntityForm.valid)
    return false;
    if(this.SubEntityId == 0) {
      this.ApiService.addSubEntity(this.subEntityForm.value)
	     .subscribe( result => {
        this.addSubEntityForm.resetForm();
        this.successBlock = true;
        this.successMsg = result.Success;
        this.toggleMessage('success');
			},
      error => {
        this.errorDisplay(error);								
			});
    } else {
      this.ApiService.addSubEntity(this.subEntityForm.value)
      .subscribe( result => {
       this.successBlock = true;
       this.successMsg = result.Success;
       this.toggleMessage('success');
     },
     error => {
       this.errorDisplay(error);								
     });
    }
  }   
  openDeactivateDialog(element,status,type) {
    var msg = 'ACTIVATE';
    var title = 'ACTIVATE';
    if(status == 'Active') {
      msg = 'DEACTIVATE';
      title = 'DEACTIVATE';
    }
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: msg,
			title: title,
      page:'deactivate-subentity',
      entityId:this.SubEntityId,
      status:status,
      obj:this.subEntityObj,
      element:element,
      type:type
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.router.navigate(['databook/admin',1010]);
				}				
		}); 
  }
  openAddAssignmentDialog() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "ADD ASSIGNMENT",
			title: 'ADD ASSIGNMENT',
      page:'add-assignment-subentity',
      subEntityId:this.SubEntityId,
      subEntityObj:this.subEntityObj,
      entityListObj:this.entityList
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getAllSubEntityAssignment(this.SubEntityId);
				}				
		}); 
  }
  openEditAssignmentDialog(item) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "EDIT ASSIGNMENT",
			title: 'EDIT ASSIGNMENT',
      page:'edit-assignment-subentity',
      subEntityId:this.SubEntityId,
      subEntityObj:this.subEntityObj,
      entityListObj:this.entityList,
      assignmentObj:item
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getAllSubEntityAssignment(item.SubEntityCode);
				}				
		}); 
  }
  
  back(type){
    if(type == 'admin') {
     this.router.navigate(['databook/admin',1010]);
    }
  } 
  reset(){
    this.addSubEntityForm.resetForm();
  }
  errorDisplay(error) {
    this.errorBlock = true;
    if(error.status == "500"){
      this.errorMsg = environment.errorMsg500;
    } else if(error.status == "400"){
      this.errorMsg = error.error.Error;
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type){
    setTimeout(() => {
     if(type == 'success'){
      this.successBlock = false;
     } else {
      this.errorBlock = false;
     }      
  }, 5000);
  }
  
}
/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}