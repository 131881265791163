import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-legislative-data-refresh',
  templateUrl: './legislative-data-refresh.component.html',
  styleUrls: ['./legislative-data-refresh.component.css']
})
export class LegislativeDataRefreshComponent implements OnInit {
  @ViewChild('file') file;
  public files: Set<File> = new Set();
  uploading = false;
  uploadSuccessful = false;
  uploadSuccess: boolean = false;
  uploadSuccessMsg: string;
  uploadErrorMsg: string;
  uploadError: boolean;
  primaryButtonText = 'Upload';
  fileSize = 0;
  awsToken: any;
  progress;
  content: any;
  showCancelButton = true;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getS3Token();

  }
  addFiles() {
    this.file.nativeElement.click();
  }
  onFilesAdded() {
    console.log("here", this.file.nativeElement.files)
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.fileSize = files[key].size;
        this.files.add(files[key]);
      }
    }
  }

  onCancel() {
    this.files.clear();
    const input = this.file.nativeElement.files;
    // input.replaceWith(input.val('').clone(true));
    // console.log('files', this.file.nativeElement.files)
    this.fileSize = 0;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.showCancelButton = true;
  }

  getS3Token() {
    this.apiService.getSTSToken()
      .subscribe(data => {
        this.awsToken = data;
      },
        error => {
        });
  }

  async onUpload() {
    this.uploading = true;
    // start the upload and save the progress map
    if (this.awsToken == null || this.awsToken == undefined) {
      this.getS3Token();
    }
    this.progress = await this.apiService.uploadLegislativeDataRefresh(this.files, this.awsToken);
    if (this.progress.ETag) {
      console.log("inside onUpload", this.awsToken)

      this.uploadSuccess = true;
      this.uploadSuccessMsg = "Successfully uploaded file.";
      this.files.clear();
      this.fileSize = 0;
    }
    else {
      this.uploadError = true;
      this.uploadErrorMsg = "Oops! There was an error uploading your file, Please try again.";
      // Hide the cancel-button    
    this.showCancelButton = false;
    }
    this.uploading = false;
    this.uploadSuccessful = false;
  }

}
