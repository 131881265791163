import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const contentType = 'text/csv;charset=utf-8;';
const reportingEntity = 'Reporting Entity';
const workLocation = 'Work Location';
const reportingSubEntity = 'Reporting Sub Entity';
@Injectable()
export class ExcelService {
    constructor() { }
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    downloadFileInvestment(data, filename = 'data') {
        const csvData = this.ConvertToCSV(data, [reportingEntity, workLocation, reportingSubEntity, 'Fixed Asset Amount', 'Currency Code']);
        console.log(csvData)
        const blob = new Blob(['\ufeff' + csvData], { type: contentType });
        const dwldLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    downloadFilePayroll(data, filename = 'data') {
        const csvData = this.ConvertToCSV(data, [reportingEntity, workLocation, reportingSubEntity, 'Production Wages', 'Non-Production Wages', 'Currency Code']);
        console.log(csvData)
        const blob = new Blob(['\ufeff' + csvData], { type: contentType });
        const dwldLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    downloadFileEmployee(data, filename = 'data') {
        const csvData = this.ConvertToCSV(data, [reportingEntity, workLocation, reportingSubEntity, 'Employee Type', 'Employee Status', 'Full Time / Part Time', 'Employee Address Line 1', 'Employee Address Line 2', 'Employee City', 'Employee State', 'Employee Zip Code', 'Employee Country', 'Employee Province', 'Employee Postal Code']);
        console.log(csvData)
        const blob = new Blob(['\ufeff' + csvData], { type: contentType });
        const dwldLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    ConvertToCSV(objArray, headerList) {
        const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        for (const index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (const index in headerList) {
                const head = headerList[index];
                line += array[i][head];
            }
            str += line + '\r\n';
        }
        return str;
    }
    ConvertToCSV1(objArray, headerList) {
        const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';

        for (const index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (const index in headerList) {
                const head = headerList[index];

                line += array[i][head] + ',';
            }
            str += line + '\r\n';
        }
        return str;
    }
    downloadFileInvestment1(data, filename = 'template', fieldName) {
        const csvData = this.ConvertToCSV1(data, fieldName);
        console.log(csvData)
        const blob = new Blob(['\ufeff' + csvData], { type: contentType });
        const dwldLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}