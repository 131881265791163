import { Component, OnInit, ViewEncapsulation,ViewChild,ElementRef,TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent {
  dataCategoryData: any=[];
  constructor(private router: Router,private ApiService: ApiService,) {  
  this.getDatacategories();
  }   
  gotoDetail(categoryId,categoryName) {
		this.router.navigate(['databook/glossary/detail',categoryId,categoryName,1014]);
  }
  gotoReport(reportId) {
		this.router.navigate(['databook/glossary/report',reportId,1015]);
  }
  
  getDatacategories(){
    this.ApiService.getDatacategories().subscribe( result => {        
    result.Datacategories.forEach(item => {
     if(item.ReportingFrequency==='Annually' && item.DataCategory!=='Sales' && item.DataCategory!=='Dealer Summary' && item.DataCategory!=='Philanthropy' && item.DataCategory!=='Pension'){
      this.dataCategoryData.push(item)
     }  
    });
      
    },
    error => {
               
    });
  }
}