export class ManualEntryFields {
   employeeSummary:any = [];
   pension:any = [];
   dealerSummary:any = [];
   contractLabour:any = [];
   goodsAndService:any = [];
   payroll:any = [];
   
   priorEmployeeSummary:Array<any> = [];
   priorPension:Array<any> = [];
   priorDealerSummary:Array<any> = [];
   priorContractLabour:Array<any> = [];
   priorGoodsAndService:Array<any> = [];
   priorPayroll:Array<any> = [];
   constructor() {
        this.employeeSummary = [{"ReportingEntityLocationCode":"Reporting Entity Location"},
                                {"SubEntityShort":"Reporting Sub Entity"},
                                {"NonProductionEmployeeCount":"Non Production Employee"},
                                {"ProductionEmployeeCount":"Production Employee"},
                                {"notRequired":"Not Required"},
                                {"Notes":"Notes"}
                            ];
        this.pension = [{"ReportingEntityLocationCode":"Reporting Entity Location"},
                        {"SubEntityShort":"Reporting Sub Entity"},
                        {"Retirees":"Number of Retirees"},
                        {"RetireesReceivingPension":"Number of Retirees Receiving Pension"},
                        {"RetireesReceivingHealthcareBenefits":"Number of Retirees Receiving Health Care Benefits"},
                        {"SurvivorReceivingBenefits":"Number of Survivors Receiving Benefits"},
                        {"notRequired":"Not Required"},
                        {"Notes":"Notes"}
                    ];
        this.dealerSummary = [{"ReportingEntityLocationCode":"Reporting Entity Location"},
                    {"SubEntityShort":"Reporting Sub Entity"},
                    {"DealerEmployeeCount":"Employee Count"},
                    {"DealerNetWorthLocal":"Net Worth"},
                    {"CurrencyCode":"Currency"},
                    {"notRequired":"Not Required"},
                    {"Notes":"Notes"}
                ];
        this.contractLabour = [{"ReportingEntityLocationCode":"Reporting Entity Location"},
                    {"SubEntityShort":"Reporting Sub Entity"},
                    {"NumberOfVendors":"Number of Contract Workers"},                   
                    {"notRequired":"Not Required"},
                    {"Notes":"Notes"}
                ];
        this.goodsAndService = [{"ReportingEntityLocationCode":"Reporting Entity Location"},
                    {"SubEntityShort":"Reporting Sub Entity"},
                    {"ExpenseAmountLocal":"Expense Amount"},
                    {"NumberOfVendors":"Number Of Vendors"},
                    {"CurrencyCode":"Currency"},                   
                    {"notRequired":"Not Required"},
                    {"Notes":"Notes"}
                ];
        this.payroll = [{"ReportingEntityLocationCode":"Reporting Entity Location"},
                {"SubEntityShort":"Reporting Sub Entity"},
                {"NonProductionWagesLocal":"Non-Prod Wages"},
                {"ProductionWagesLocal":"Prod Wages"},
                {"CurrencyCode":"Currency"},                   
                {"notRequired":"Not Required"},
                {"Notes":"Notes"}
            ];
        this.priorEmployeeSummary = [{
            NonProductionEmployeeCount:"priorNonProductionEmployeeCount",
            ProductionEmployeeCount:"priorProductionEmployeeCount"   
        }];
        this.priorPension = [{
            Retirees:"priorRetirees",
            RetireesReceivingPension:"priorRetireesReceivingPension",
            RetireesReceivingHealthcareBenefits:"priorRetireesReceivingHealthcareBenefits",
            SurvivorReceivingBenefits:"priorSurvivorReceivingBenefits"
        }];
        this.priorDealerSummary = [{
            DealerEmployeeCount:"priorDealerEmployeeCount",
            DealerNetWorthLocal:"priorDealerNetWorthLocal"
        }];
        this.priorContractLabour = [{
            NumberOfVendors:"priorNumberOfVendors"
        }];
        this.priorGoodsAndService = [{
            ExpenseAmountLocal:"priorExpenseAmountLocal",
            NumberOfVendors:"priorNumberOfVendors"
        }];
        this.priorPayroll = [{
                NonProductionWagesLocal:"priorNonProductionWagesLocal",
                ProductionWagesLocal:"priorProductionWagesLocal"
        }];
   }
} 