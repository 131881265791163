import { Component, OnInit,ViewChild,AfterViewInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table'; 
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-glossary-detail',
  templateUrl: './glossaryDetail.component.html',
  styleUrls: ['./glossaryDetail.component.css']
})
export class GlossaryDetailComponent implements OnInit,AfterViewInit {
  displayedColumns: string[] = ['id','FieldName','Description','Size','Required'];
  dataSource = new MatTableDataSource();
  pageLength: number;
  pageSize: number = 10;
  dataLength: number;
  dataCategoryId: number;
  dataCategoryName: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  glossaryDesc:any;


  constructor(private router: Router,private route: ActivatedRoute,private ApiService: ApiService) {
    this.dataCategoryId = this.route.snapshot.params.dataCategoryId;
    this.dataCategoryName = this.route.snapshot.params.dataCategoryName;
      this.ApiService.getGlossaryDetailsColumns(this.dataCategoryId).subscribe(
	      result => {
          this.dataSource.data = result.glossary[0];					
          this.dataLength = result.glossary[0].length;
          this.glossaryDesc = result.glossary[1][0].Description.split('|');
          this.pageLength = Math.ceil(this.dataLength / this.pageSize);
          setTimeout(() => this.dataSource.paginator = this.paginator);     
	      }
    );
  }   
  ngOnInit() { }
  ngAfterViewInit() {		
		this.dataSource.sort = this.sort;		
  }
  back() {
		this.router.navigate(['databook/glossary',1013]);
	}
}