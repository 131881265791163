import { Component, OnInit, ViewChild,ElementRef,Renderer2,AfterViewInit,ViewEncapsulation,Input} from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../../dialog.component';
import { AdminDialogComponent } from '../../adminDialog.component';
import { ManualEntryFields } from '../../utility/manualEntryFields';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manualEntry',
  templateUrl: './manualEntry.component.html',
  styleUrls: ['./manualEntry.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualEntryComponent implements OnInit,AfterViewInit{	
  displayColumns = [];
  displayHeaders = [];
  locationData = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageLength: number;
  pageSize: number = 200;
  dataLength: number;
  dataCategoryId:number;
  entityId:number;
  approverForm:FormGroup;
  approverBlock: boolean = true;
  manualEntryBlock: boolean = false;
  helpTextBlock: boolean = false;
  successBlock:boolean = false;
  successMsg: string;
  errorBlock:boolean = false;
  errorMsg: string; 
  columns: Array<any> = [];
  columnLength: number;
  currentReportingPeriod:any;
  currentUser: any;
  categoryURL: string;
  approverDetails: any;
  currencyCodes;any;
  entityClass:string;
  disabledInputs = [];
  helpText:string;
  priorColumns:any = [];
  priorReportingPeriod:any;
  constructor(public dialog: MatDialog,private ApiService: ApiService,private route: ActivatedRoute,
    private frmBuilder:FormBuilder, private router: Router,
    private elRef:ElementRef,private renderer: Renderer2,
    private manualEntryFields:ManualEntryFields) {
      this.dataCategoryId = this.route.snapshot.params.dataCategoryId;
      this.entityId = this.route.snapshot.params.entityId;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      
      if(this.dataCategoryId == 14) {
        manualEntryFields.employeeSummary.forEach(element => {
          let key = Object.keys(element)[0];
          this.displayColumns.push(key);
          this.displayHeaders.push(element[key]);
          this.columns.push({ name: key, label: element[key] });
        }); 
        this.priorColumns = manualEntryFields.priorEmployeeSummary[0];
        this.entityClass = "employee-summary";
      } else if(this.dataCategoryId == 10) {
        manualEntryFields.pension.forEach(element => {
          let key = Object.keys(element)[0];
          this.displayColumns.push(key);
          this.displayHeaders.push(element[key]);
          this.columns.push({ name: key, label: element[key] });        
        });
        this.priorColumns = manualEntryFields.priorPension[0];
        this.entityClass = "pension";
      } else if(this.dataCategoryId == 15) {
        manualEntryFields.dealerSummary.forEach(element => {
          let key = Object.keys(element)[0];
          this.displayColumns.push(key);
          this.displayHeaders.push(element[key]);
          this.columns.push({ name: key, label: element[key] });        
        });
        this.priorColumns = manualEntryFields.priorDealerSummary[0];
        this.entityClass = "dealer-summary";
        this.getCurrencyCode();
      } else if(this.dataCategoryId == 9) {
        manualEntryFields.contractLabour.forEach(element => {
          let key = Object.keys(element)[0];
          this.displayColumns.push(key);
          this.displayHeaders.push(element[key]);
          this.columns.push({ name: key, label: element[key] });        
        });
        this.priorColumns = manualEntryFields.priorContractLabour[0];
        this.entityClass = "contract-labour";
      } else if(this.dataCategoryId == 7) {
        manualEntryFields.goodsAndService.forEach(element => {
          let key = Object.keys(element)[0];
          this.displayColumns.push(key);
          this.displayHeaders.push(element[key]);
          this.columns.push({ name: key, label: element[key] });        
        });
        this.priorColumns = manualEntryFields.priorGoodsAndService[0];
        this.entityClass = "goods-and-service";
        this.getCurrencyCode();
      } else if(this.dataCategoryId == 13) {
        manualEntryFields.payroll.forEach(element => {
          let key = Object.keys(element)[0];
          this.displayColumns.push(key);
          this.displayHeaders.push(element[key]);
          this.columns.push({ name: key, label: element[key] });        
        });
        this.priorColumns = manualEntryFields.priorPayroll[0];
        this.entityClass = "payroll";
        this.getCurrencyCode();
      }
    this.columnLength = this.columns.length;
  }
  ngOnInit() {
    this.approverForm = this.frmBuilder.group({
      approverName: [null, Validators.required],
      approverTitle: [null, Validators.required],			
      statusNotes: [null, Validators.required],
      approverEmail:[null, Validators.email],
      approverDate:[formatDate(new Date(),'yyyy-MM-dd','en'),Validators.required],
      datacategorykey:[],
      approverId:[],
      entityCode:[],
      reportingPeriodKey:[],      
      email:[],
      name:[],
      columnList: this.frmBuilder.array([])
    });      
     this.getManualEntryData(this.entityId,this.dataCategoryId);   
  }
  get approverName() { return this.approverForm.get('approverName'); }
  get approverTitle() { return this.approverForm.get('approverTitle'); }
  get approverDate() { return this.approverForm.get('approverDate'); }
  get statusNotes() { return this.approverForm.get('statusNotes'); }
  get approverEmail() { return this.approverForm.get('approverEmail');}
  ngAfterViewInit() {				
	}
  onSubmitApprover() {
    if(!this.approverForm.valid)
    return;
    this.requiredPatchValue();
    this.ApiService.saveApproverManualEntry(this.approverForm.value)
        .subscribe( result => {
          this.successBlock = true;
          this.successMsg = result.Message;
          this.toggleMessage('success');            
       },
       error => {         
        this.errorDisplay(error);				
       });
  }
  saveAll() {  
    var selectedItem = false;
    var notesArray = [],emptyFieldArray = [];
    if(!this.approverForm.valid || this.approverForm.controls.approverDate.value === "Invalid date") {
      if(this.approverForm.controls.approverDate.value === "Invalid date")
      {
        this.approverForm.controls["approverDate"].setValue(null);
      }
      this.approverForm.get('approverName').markAsTouched();
      this.approverForm.get('approverTitle').markAsTouched();
      this.approverForm.get('approverDate').markAsTouched();
      this.approverForm.get('statusNotes').markAsTouched();
      this.approverForm.get('approverEmail').markAsTouched();
      this.approverForm.controls['columnList'].markAsTouched();
      return;
    }	else {
      this.approverForm.value.columnList.forEach(function (item,index) {
        if(item.notRequired) {
          selectedItem = true;
          notesArray.push(item.Notes);
        } else {
          emptyFieldArray.push(item);
        }
      });
      if(notesArray.indexOf(null) !==-1 || notesArray.indexOf(undefined) !==-1 || notesArray.indexOf("") !==-1) {
        this.errorDialog('One or more "Not Required" checkboxes are checked but no corresponding notes have been filled in. Please supply justification on why the entry is "Not Required."');
        return;
      } else {
        var emptyCheckArray = [];
        for(var i =0;i<emptyFieldArray.length;i++){          
          if(this.dataCategoryId == 10) { 
            emptyCheckArray.push(emptyFieldArray[i].Retirees);
            emptyCheckArray.push(emptyFieldArray[i].RetireesReceivingPension);
            emptyCheckArray.push(emptyFieldArray[i].RetireesReceivingHealthcareBenefits);
            emptyCheckArray.push(emptyFieldArray[i].SurvivorReceivingBenefits);
          } else if(this.dataCategoryId == 14) {
            emptyCheckArray.push(emptyFieldArray[i].ProductionEmployeeCount);
            emptyCheckArray.push(emptyFieldArray[i].NonProductionEmployeeCount);
          } else if(this.dataCategoryId == 15) {
            emptyCheckArray.push(emptyFieldArray[i].DealerEmployeeCount);
            emptyCheckArray.push(emptyFieldArray[i].DealerNetWorthLocal);
            emptyCheckArray.push(emptyFieldArray[i].CurrencyCode);
          } else if(this.dataCategoryId == 9) {
            emptyCheckArray.push(emptyFieldArray[i].NumberOfVendors);
          } else if(this.dataCategoryId == 7) {
            emptyCheckArray.push(emptyFieldArray[i].ExpenseAmountLocal);
            emptyCheckArray.push(emptyFieldArray[i].NumberOfVendors);
            emptyCheckArray.push(emptyFieldArray[i].CurrencyCode);
          }  else if(this.dataCategoryId == 13) {
            emptyCheckArray.push(emptyFieldArray[i].NonProductionWagesLocal);
            emptyCheckArray.push(emptyFieldArray[i].ProductionWagesLocal);
            emptyCheckArray.push(emptyFieldArray[i].CurrencyCode);
          }
        }  
        if(emptyCheckArray.indexOf(null) !== -1 || emptyCheckArray.indexOf(undefined) !== -1) {
          this.errorDialog('One or more fields or rows below have a failure. Please double check your values and try your save again.');
          return;
        } else {
          this.saveDetails();
        }        
      }     
    }	
  }
  saveDetails() {
    this.requiredPatchValue();
    this.ApiService.saveManualEntryDetails(this.approverForm.value)
        .subscribe( result => {
          this.successBlock = true; 
          this.successMsg = result.Message;
          setTimeout(() => {
            this.router.navigate(['databook/DataProviderDashboard/1018']);
          }, 1500)
       },
       error => {
        this.errorDisplay(error);				
       });
  }
  requiredPatchValue() {
    this.approverForm.patchValue({
      reportingPeriodKey:this.currentReportingPeriod.ReportingPeriodKey,
      datacategorykey:this.dataCategoryId,
      entityCode:this.entityId,
      email:this.currentUser.emailAddress,
      name:this.currentUser.firstName+' '+this.currentUser.lastName
    });
  }
  errorDisplay(error){
    this.errorBlock = true;
    if(error.status =="500"){
      this.errorMsg = environment.errorMsg500;
    } else if(error.status =="400"){
      this.errorMsg = error.error.Message;
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type) {
    setTimeout(() => {
     if(type == 'success'){
      this.successBlock = false;
     } else {
      this.errorBlock = false;
     }      
  }, 5000);
  }
  errorDialog(msg) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: msg,
      page: "error-message",
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'750px'});  
  }
  setApproverData() {
    this.approverForm.patchValue({
      approverName: this.approverDetails.ApproverName,
      approverTitle: this.approverDetails.ApproverTitle,
      approverEmail:this.approverDetails.ApproverEmail,
      approverDate:formatDate(this.approverDetails.ApproverDate,'yyyy-MM-dd','en'),
      approverId:this.approverDetails.ApproverId,
      statusNotes:this.approverDetails.Notes
    });
  }
  getManualEntryData(entityId,dataCategoryId) {
    if(dataCategoryId == 10) {
      this.categoryURL = environment.getPensionManualEntry;
    } else if(dataCategoryId == 14){
      this.categoryURL = environment.getEmployeeSummaryManualEntry;
    } else if(dataCategoryId == 15){
      this.categoryURL = environment.getDealerSummaryManualEntry;
    } else if(dataCategoryId == 9){
      this.categoryURL = environment.getContractLabourManualEntry;
    } else if(dataCategoryId == 7){
      this.categoryURL = environment.getGoodsAndPurchaseManualEntry;
    } else if(dataCategoryId == 13){
      this.categoryURL = environment.getPayrollManualEntry;
    }

    this.ApiService.getManualEntryData(entityId,dataCategoryId,this.categoryURL)
    .subscribe( result => {        
      this.locationData.data = result.details;
      this.currentReportingPeriod = result.reportingPeriod;
      this.priorReportingPeriod = result.priorReportingPeriod;
      this.approverDetails = result.approver;
      this.helpText = result.helpText;
      this.dataLength = result.details.length;
			this.pageLength = Math.ceil(this.dataLength / this.pageSize);
      setTimeout(() => this.locationData.paginator = this.paginator);
      if(result.approver.ApproverTitle != undefined) {
        this.setApproverData();
      }      
      const control  = <FormArray>this.approverForm.controls['columnList'];
      result.details.forEach(item => {
        control.push(this.patchValues(item))
      });
          
    },
    error => {
      this.errorDisplay(error);         
    });
  }
  getCurrencyCode(){
    this.ApiService.getCurrencyCode()
    .subscribe( result => {        
      this.currencyCodes = result.currency;          
    },
    error => {
      this.errorDisplay(error);         
    });
  }
  patchValues(obj) {
    if(this.dataCategoryId == 14) {
      var NonProductionEmployeeCount,ProductionEmployeeCount = null;
      var disabled = true;
      var checkboxValue = true;
    if(obj.currentNonProductionEmployeeCount != -1 && obj.currentNonProductionEmployeeCount != null) { 
      NonProductionEmployeeCount = obj.currentNonProductionEmployeeCount;
      disabled = false;
      checkboxValue = null;
    }
    if(obj.currentProductionEmployeeCount != -1 && obj.currentProductionEmployeeCount != null) {
      ProductionEmployeeCount = obj.currentProductionEmployeeCount;
      disabled = false;
      checkboxValue = null;
    }
    this.disabledInputs.push(disabled);

    return this.frmBuilder.group({
      NonProductionEmployeeCount : [NonProductionEmployeeCount,Validators.pattern("^[0-9]*$")],
      ProductionEmployeeCount: [ProductionEmployeeCount,Validators.pattern("^[0-9]*$")],
      notRequired:[checkboxValue],
      Notes:[obj.Notes],
      DatabookLocationCode:[obj.DatabookLocationCode],
      ReportingEntityLocationCode:[obj.ReportingEntityLocationCode],
      SubEntityShort:[obj.SubEntityShort],
      Id:[obj.Id]
    });
    } else if(this.dataCategoryId == 10) {
      var Retirees,RetireesReceivingHealthcareBenefits,RetireesReceivingPension,SurvivorReceivingBenefits = null;
      var disabled = true;
      var checkboxValue = true;
      if(obj.currentRetirees != -1 && obj.currentRetirees != null) { 
        Retirees = obj.currentRetirees;
        disabled = false;
        checkboxValue = null;
      }
      if(obj.currentRetireesReceivingHealthcareBenefits != -1 && obj.currentRetireesReceivingHealthcareBenefits != null) {
        RetireesReceivingHealthcareBenefits = obj.currentRetireesReceivingHealthcareBenefits;
        disabled = false;
        checkboxValue = null;
      }
      if(obj.currentRetireesReceivingPension != -1 && obj.currentRetireesReceivingPension != null) {
        RetireesReceivingPension = obj.currentRetireesReceivingPension;
        disabled = false;
        checkboxValue = null;
      }
      if(obj.currentSurvivorReceivingBenefits != -1 && obj.currentSurvivorReceivingBenefits != null) {
        SurvivorReceivingBenefits = obj.currentSurvivorReceivingBenefits;
        disabled = false;
        checkboxValue = null;
      }
      this.disabledInputs.push(disabled);
      return this.frmBuilder.group({      
      entityLocation:[obj.ReportingEntityLocationCode],
      reportingSubEntity:[obj.SubEntityCode],
      Retirees: [Retirees,Validators.pattern("^[0-9]*$")],
      RetireesReceivingPension: [RetireesReceivingHealthcareBenefits,Validators.pattern("^[0-9]*$")],
      RetireesReceivingHealthcareBenefits: [RetireesReceivingPension,Validators.pattern("^[0-9]*$")],
      SurvivorReceivingBenefits: [SurvivorReceivingBenefits,Validators.pattern("^[0-9]*$")],
      notRequired:[checkboxValue],
      Notes:[obj.Notes],      
      DatabookLocationCode:[obj.DatabookLocationCode],
      ReportingEntityLocationCode:[obj.ReportingEntityLocationCode],
      SubEntityCode:[obj.SubEntityCode],
      SubEntityShort:[obj.SubEntityShort],
      Id:[obj.Id]
    });
    } else if(this.dataCategoryId == 15) {
    var DealerEmployeeCount,DealerNetWorthLocal = null;
    var currencyCode = 'USD';
    var disabled = true;
    var checkboxValue = true;
    if(obj.currentDealerEmployeeCount != -1 && obj.currentDealerEmployeeCount != null) { 
      DealerEmployeeCount = obj.currentDealerEmployeeCount;
      disabled = false;
      checkboxValue = null;
    }
    if(obj.currentDealerNetWorthLocal != 0 && obj.currentDealerNetWorthLocal != null) { 
      DealerNetWorthLocal = obj.currentDealerNetWorthLocal;
      disabled = false;
      checkboxValue = null;
    }
    if(obj.currentCurrencyCode && obj.currentCurrencyCode != ''){
      currencyCode = obj.currentCurrencyCode;
    }
    this.disabledInputs.push(disabled);
    return this.frmBuilder.group({      
      DealerEmployeeCount: [DealerEmployeeCount,Validators.pattern("^[0-9]*$")],
      DealerNetWorthLocal: [DealerNetWorthLocal,Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
      CurrencyCode: [currencyCode],      
      notRequired:[checkboxValue],
      Notes:[obj.Notes],
      DatabookLocationCode:[obj.DatabookLocationCode],
      ReportingEntityLocationCode:[obj.ReportingEntityLocationCode],
      SubEntityCode:[obj.SubEntityCode],
      SubEntityShort:[obj.SubEntityShort],
      Id:[obj.Id]
    });
    } else if(this.dataCategoryId == 9) {
      var NumberOfVendors = null;
      var disabled = true;
      var checkboxValue = true;
    if(obj.currentNumberOfVendors != -1 && obj.currentNumberOfVendors != null) { 
      NumberOfVendors = obj.currentNumberOfVendors;
      disabled = false;
      checkboxValue = null;
    }
    this.disabledInputs.push(disabled);
      return this.frmBuilder.group({       
        NumberOfVendors: [NumberOfVendors,Validators.pattern("^[0-9]*$")],    
        notRequired:[checkboxValue],
        Notes:[obj.Notes],
        DatabookLocationCode:[obj.DatabookLocationCode],
        ReportingEntityLocationCode:[obj.ReportingEntityLocationCode],
        SubEntityCode:[obj.SubEntityCode],
        SubEntityShort:[obj.SubEntityShort],
        Id:[obj.Id]
      });
    } else if(this.dataCategoryId == 7) {
      var ExpenseAmountLocal,NumberOfVendors = null;
      var currencyCode = 'USD';
      var disabled = true;
      var checkboxValue = true;
      if(obj.currentExpenseAmountLocal != 0 && obj.currentExpenseAmountLocal != null) { 
        ExpenseAmountLocal = obj.currentExpenseAmountLocal;
        disabled = false;
        checkboxValue = null;
      }
      if(obj.currentNumberOfVendors != -1 && obj.currentNumberOfVendors != null) { 
        NumberOfVendors = obj.currentNumberOfVendors;
        disabled = false;
        checkboxValue = null;
      }
      if(obj.currentCurrencyCode && obj.currentCurrencyCode != ''){
        currencyCode = obj.currentCurrencyCode;
      }
      this.disabledInputs.push(disabled);
      return this.frmBuilder.group({      
        ExpenseAmountLocal: [ExpenseAmountLocal,Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
        NumberOfVendors: [NumberOfVendors,Validators.pattern("^[0-9]*$")],
        CurrencyCode: [currencyCode],      
        notRequired:[checkboxValue],
        Notes:[obj.Notes],
        DatabookLocationCode:[obj.DatabookLocationCode],
        ReportingEntityLocationCode:[obj.ReportingEntityLocationCode],
        SubEntityCode:[obj.SubEntityCode],
        SubEntityShort:[obj.SubEntityShort],
        Id:[obj.Id]
      });
      } else if(this.dataCategoryId == 13) {
        var NonProductionWagesLocal,ProductionWagesLocal = null;
        var currencyCode = 'USD';
        var disabled = true;
        var checkboxValue = true;
        if(obj.currentNonProductionWagesLocal != -1 && obj.currentNonProductionWagesLocal != null && obj.currentNonProductionWagesLocal != '') { 
          NonProductionWagesLocal = obj.currentNonProductionWagesLocal;
          disabled = false;
          checkboxValue = null;
        }
        if(obj.currentProductionWagesLocal != -1 && obj.currentProductionWagesLocal != null && obj.currentProductionWagesLocal != '') { 
          ProductionWagesLocal = obj.currentProductionWagesLocal;
          disabled = false;
          checkboxValue = null;
        }
        if(obj.currentCurrencyCode && obj.currentCurrencyCode != ''){
          currencyCode = obj.currentCurrencyCode;
        }
        this.disabledInputs.push(disabled);
        return this.frmBuilder.group({      
          NonProductionWagesLocal: [NonProductionWagesLocal,Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
          ProductionWagesLocal: [ProductionWagesLocal,Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
          CurrencyCode: [currencyCode],      
          notRequired:[checkboxValue],
          Notes:[obj.Notes],
          DatabookLocationCode:[obj.DatabookLocationCode],
          ReportingEntityLocationCode:[obj.ReportingEntityLocationCode],
          SubEntityCode:[obj.SubEntityCode],
          SubEntityShort:[obj.SubEntityShort],
          Id:[obj.Id]
        });
        }
  }
  selectNotRquired(event,item) {
    var selectedFlag = false; 
    var element = this.elRef.nativeElement.querySelectorAll('.column'+item);
    var elementLength = element.length;
    for(var i=0;i<elementLength;i++) {
      if(element[i].children[0].children[0].children[2].children[0].classList.contains('disabledinput')){
        selectedFlag = false;
        element[i].children[0].children[0].children[2].children[0].removeAttribute('disabled',true);
        element[i].children[0].children[0].children[2].children[0].classList.remove('disabledinput');
        element[i].children[0].classList.remove('input-notrequired');        
      } else {
        selectedFlag = true;
        element[i].children[0].children[0].children[2].children[0].setAttribute('disabled',true);
        element[i].children[0].children[0].children[2].children[0].classList.add('disabledinput');        
        element[i].children[0].classList.add('input-notrequired');
        element[i].children[0].children[0].children[2].children[0].value = null
      }
    }
    if(selectedFlag) {
        if(this.dataCategoryId == 14) {
           this.approverForm.controls['columnList']['controls'][item].controls["NonProductionEmployeeCount"].setValue(null);
           this.approverForm.controls['columnList']['controls'][item].controls["ProductionEmployeeCount"].setValue(null);
        } else if(this.dataCategoryId == 10) {
          this.approverForm.controls['columnList']['controls'][item].controls["Retirees"].setValue(null);
          this.approverForm.controls['columnList']['controls'][item].controls["RetireesReceivingPension"].setValue(null);
          this.approverForm.controls['columnList']['controls'][item].controls["RetireesReceivingHealthcareBenefits"].setValue(null);
          this.approverForm.controls['columnList']['controls'][item].controls["SurvivorReceivingBenefits"].setValue(null);
        } else if(this.dataCategoryId == 15) {
          this.approverForm.controls['columnList']['controls'][item].controls["DealerEmployeeCount"].setValue(null);
          this.approverForm.controls['columnList']['controls'][item].controls["DealerNetWorthLocal"].setValue(null);
          this.approverForm.controls['columnList']['controls'][item].controls["CurrencyCode"].setValue(null);
      } else if(this.dataCategoryId == 9) {
        this.approverForm.controls['columnList']['controls'][item].controls["NumberOfVendors"].setValue(null);
      } else if(this.dataCategoryId == 7) {
        this.approverForm.controls['columnList']['controls'][item].controls["ExpenseAmountLocal"].setValue(null);
        this.approverForm.controls['columnList']['controls'][item].controls["NumberOfVendors"].setValue(null);
        this.approverForm.controls['columnList']['controls'][item].controls["CurrencyCode"].setValue(null);
      } else if(this.dataCategoryId == 13) {
        this.approverForm.controls['columnList']['controls'][item].controls["NonProductionWagesLocal"].setValue(null);
        this.approverForm.controls['columnList']['controls'][item].controls["ProductionWagesLocal"].setValue(null);
        this.approverForm.controls['columnList']['controls'][item].controls["CurrencyCode"].setValue(null);
      }    
        this.approverForm.controls['columnList'].markAsUntouched();
    }  
  }
  back(type){
    if(type == 'admin') {
     this.router.navigate(['databook/admin',1010]);
    } else if(type == 'provider'){
      this.router.navigate(['databook/DataProviderDashboard',1018]);
    }
  }
  tabs($event,type) {
    var temp = $event.currentTarget.parentNode.childNodes;    
    for(var i =0;i<temp.length;i++) {      
        if(temp[i].classList.contains('active')) {
          temp[i].classList.remove('active');
        }
    }
  $event.currentTarget.classList.add('active');
    this.approverBlock = false;
    this.manualEntryBlock = false;
    this.helpTextBlock = false;
    if(type == 1) {
      this.approverBlock = true;   
    } else if(type == 2) {
      this.manualEntryBlock = true;
    } else if(type == 4) {
      this.helpTextBlock = true;    
    } else {
      this.approverBlock = true; 
    }
  }  
  
}
