import { Component, OnInit,ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router'; 
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialog,MatDialogConfig} from "@angular/material/dialog";
import { FormControl,FormGroupDirective,NgForm } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { EncryptService } from '../../services//encrypt.service';
import { DialogComponent } from '../../dialog.component';
import { MessageComponent } from '../../message.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-data-provider-dashboard',
  templateUrl: './dataProviderDashboard.component.html',
  styleUrls: ['./dataProviderDashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DataProviderDashboardComponent implements OnInit {	
  encryptText: string;
  currentUser: any;
  dataCategoryList: any=[];
  matcher = new MyErrorStateMatcher();
  fileUploadEnable:boolean = false;
  manualEntryEnable:boolean = false;
  successBlock:boolean = false;
  successMsg: string;
  errorBlock:boolean = false;
  errorMsg: string;
  openReportingPeriodKeys:any;
  currentReportingPeriod:any;
constructor(private ApiService: ApiService,
  private router: Router,private encryptService:EncryptService,  
  private dialog: MatDialog) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
  }
  ngOnInit() {   
    this.getDataprovidersDatacategory();
  }
  gotoUpload(categoryId:number,entityId:string){
		this.router.navigate(['databook/fileUpload',categoryId,entityId,1011]);
  }
  gotoManualEntry(categoryId:number,entityId:string){
    this.router.navigate(['databook/manualEntry',categoryId,entityId,1011]);
  }
  openDialaogRefreshReports(){
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "Refresh report will be execute in background process, Data will be refresh once this process complete.",
			title: 'REFRESH REPORTS',
			page:'refresh-report'
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:MessageComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){        
         this.refreshReports(); 
				}				
		});    
  }
  refreshReports() {
  this.ApiService.refreshAllReports().subscribe(
      data => {
      },
      error => {
      });
  }
  getDataprovidersDatacategory() {
    this.encryptText = this.encryptService.convertText(this.currentUser.emailAddress,'provider','encrypt');    
    this.ApiService.getDataprovidersDatacategory(this.currentUser.emailAddress).subscribe(
      data => {
         data.fileToUpload.forEach(item=>{
           if(item.Datacategory!=='Dealer Summary' && item.Datacategory!=='Philanthropy' && item.Datacategory!=='Supplier Impact' && item.Datacategory!=='Sales' && item.Datacategory!=='Pension'){
             this.dataCategoryList.push(item)
           }
         });
        this.currentReportingPeriod = data.reportingPeriod;
        this.openReportingPeriodKeys = data.dataCategoryKeyList;
        data.fileToUpload.forEach(element => {
          if(element.InputMethod == "I"){
            this.fileUploadEnable = true;
          }
          if(element.InputMethod == "M"){
            this.manualEntryEnable = true;
          }
        });    
        
      },
      error => {
        this.errorDisplay(error);
      });
  }
  errorDisplay(error) {
    this.errorBlock = true;
    if(error.status =="500") {
      this.errorMsg = environment.errorMsg500;
    } else if(error.status =="400") {
      this.errorMsg = error.error.Message;
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type){
    setTimeout(() => {
     if(type == 'success'){
      this.successBlock = false;
     } else {
      this.errorBlock = false;
     }      
  }, 5000);
  } 
}

/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}