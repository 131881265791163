import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { EncryptService } from '../services//encrypt.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent implements OnInit {	
  reportLinks:any; 
  successBlock:boolean;
  successMsg:string;
  errorBlock:boolean;
  errorMsg:string;
  currentUser:any;
  encryptText:string;
	objectKeys = Object.keys;
	columnLength:number = 4;
	sectionList:any;
	contentList:any;
	//columnLength: number = 5;
	columnLengthGlossary:number = 7;
	@ViewChild('downloadLinks') downloadLinks: ElementRef<HTMLElement>;
	downloadFileURL;
	constructor(private ApiService: ApiService,private route: ActivatedRoute,private encryptService:EncryptService) { 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(this.currentUser && (this.currentUser.role == 'Admin' || this.currentUser.role == 'Super Admin')){
			this.getReportLink();
		}
		this.getGlossarySection();
  	this.getGlossaryContent();
  }
	ngOnInit() {
			 
  }
  getReportLink(){
    this.encryptText = this.encryptService.convertText(this.currentUser.emailAddress,'provider','encrypt');
    this.ApiService.getReportLinks(this.currentUser.emailAddress).subscribe(
			data => { 
        if(!data.Error){
					this.reportLinks = data.report;
					if(data.report.length == 1) {
						this.columnLength = 12;
					} else if(data.report.length == 2){
						this.columnLength = 6;
					} else if(data.report.length >= 3) {
						this.columnLength = 4;
					}
        }     

			},
      error => {
				this.errorDisplay(error);		
			});
	}
	getGlossarySection(){
    this.ApiService.getSectionList().subscribe(
      data => { 
	    data.sections=data.sections.filter(item=>item.sectionName!=='Monthly Reports')
        this.sectionList= data.sections;
        if(data.sections.length == 1) {
          this.columnLengthGlossary = 12;
        } else if(data.sections.length == 2) {
          this.columnLengthGlossary = 6;
        } else {
					this.columnLengthGlossary = 4;
				}
      },
      error => {
     });
  }
  getGlossaryContent(){
    this.ApiService.getAllContentList().subscribe(
      data => {
        this.contentList= data.contents;
      },
      error => {
     });
  }
  errorDisplay(error) {
		this.errorBlock = true;
		if(error.status =="500"){
		  this.errorMsg = environment.errorMsg500;
		} else if(error.status =="400"){
		  this.errorMsg = error.error.Message;
		} else {
		  this.errorMsg = environment.errorMsg502;
		}
		this.toggleMessage('error');
	  }
	toggleMessage(type){
		setTimeout(() => {
		 if(type == 'success'){
		  this.successBlock = false;
		 } else {
		  this.errorBlock = false;
		 }      
	  }, 50000);
	}
	openFile(path) {
		var fileName = path.split('/');
		this.ApiService.getS3FileURL(environment.reportsBucket,fileName[fileName.length-1],fileName[fileName.length-2]).subscribe(
			data => {
				window.open(data.SignedURL,'_blank');
				/*this.downloadFileURL = data.SignedURL;
				let el: HTMLElement = this.downloadLinks.nativeElement;
    		el.click();*/
			},error => {
		})
	}
	
}