import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class EncryptService {
  conversionOutput: string;
  constructor() {    
  }
  convertText(textToConvert,password,encryptMode) {
    if (textToConvert.trim() === "" || password.trim() === "") {      
      return;
    }
    else {
      if (encryptMode == 'encrypt') {
        this.conversionOutput = CryptoJS.AES.encrypt(textToConvert.trim(), password.trim()).toString();
      }
      else {
        this.conversionOutput = CryptoJS.AES.decrypt(textToConvert.trim(), password.trim()).toString(CryptoJS.enc.Utf8);
      }
      return this.conversionOutput;
    }
  }
}
