import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,NavigationEnd,NavigationCancel,GuardsCheckEnd } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
    loginDomain = environment.loginDomain;
    clientId = environment.loginURLPramClientId;
    responseType = environment.loginURLPramResponseType;
    redirectURL;
    scope = environment.loginURLPramScope;
    responseMode = environment.loginURLPramResponseMode;
    state = environment.loginURLPramState;
    nonce = environment.loginURLPramNonce;
    
   constructor(private router: Router,
        private spinner: SpinnerVisibilityService) {                    
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
        let session_access_token = localStorage.getItem('accessToken');
        let access_token
        let token = window.location.href.split('access_token=')[1];
		if(token){
         access_token=token.split('&token_type')[0];
        }
        this.redirectURL = window.location .protocol + "//" + window.location.host;         			
		if(access_token || session_access_token){            
           return true;
        } else {
        // not logged in so redirect to login page with the return url
        this.spinner.show();        
        this.spinner.hide();
        window.location.href = this.loginDomain+"?client_id="+this.clientId+"&response_type="+this.responseType+"&redirect_uri="+this.redirectURL+"&scope="+this.scope+"&response_mode="+this.responseMode+"&state="+this.state+"&nonce="+this.nonce;
        return false;
        }
    }
}