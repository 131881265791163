import { Component, OnInit,ViewEncapsulation} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import * as pbi from 'powerbi-client';
import * as models from 'powerbi-models';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-economic',
  templateUrl: './economicImpact.component.html',
  styleUrls: ['./economicImpact.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EconomicImpactComponent implements OnInit {	

reportPage: string;
reportId: string;
permissions = models.Permissions.All;	
layOut:any = models.LayoutType.Custom;
constructor(private ApiService: ApiService,private route: ActivatedRoute,private router: Router) {
	this.reportPage = this.route.snapshot.params.reportPage;
  this.reportId = this.route.snapshot.params.reportId; 
  
}
ngOnInit() {	
  this.getReports(this.reportId);
  let isMobile = false;
  isMobile = this.ApiService.isMobile();	
 if(isMobile){
   this.layOut = models.LayoutType.MobilePortrait
 }
}
gotoReport() {
  this.router.navigate(['databook/reports',1004]);
}  
/** -----------------------Power BI Report embed code starts ----------------------------**/
getReports(id) {
this.ApiService.getReportsData(id).subscribe(
     data => { 
   // Report's Secured Token     
   const accessToken = data.token;   
   // Embed URL 
   const embedUrl = data.embedUrl;   
   // Report ID
   const embedReportId = data.reportId;   
   // Configuration used to describe the what and how to embed.	
   const setting:any = {
   filterPaneEnabled: true,
   navContentPaneEnabled: true,
   /*pageView: 'fitToWidth',
   layoutType: this.layOut,
   customLayout: {			
   displayOption: models.DisplayOption.ActualSize
 }*/
 
};	
 let config = {
     type: 'report',		
     tokenType:models.TokenType.Embed,
     accessToken: accessToken,
     embedUrl: embedUrl,
     id: embedReportId,
 permissions:this.permissions,
 settings:setting       
 };

 // Grab the reference to the div HTML element that will host the report.
 const reportContainer = <HTMLElement>document.getElementById('reportContainer');

 // Embed the report and display it within the div container.
 const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
 const report = powerbi.embed(reportContainer, config);
 //report.fullscreen();
 // Report.off removes a given event handler if it exists.
 report.off("loaded");

 // Report.on will add an event handler which prints to Log window.
 report.on("loaded", function() {
 });
     }
 );
}
/** -----------------------Power BI Report embed code ends----------------------------**/
}