import { Component, OnInit,Renderer2 } from '@angular/core';
import { Router,NavigationEnd,NavigationStart } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { ApiService } from './services/api.service';
import { environment } from '../environments/environment';
import { interval} from "rxjs";

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
public spinkit = Spinkit;
currentUser: any;
loggedIn: string;
userProfilePopup:boolean = false;
adminMenuPopup: boolean = false;
responsiveMenuIcon: boolean = true;
responsiveMenu: boolean = false;
linksMenuPopup:boolean = false;
isIEOrEdge:boolean = false;
EmailId : string = '';
URL : string = '';
constructor(private renderer: Renderer2,private router: Router,private apiService:ApiService) {	
	this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
	console.log(this.isIEOrEdge);
	router.events.subscribe(s => {            
		if (s instanceof NavigationStart) {
			this.userProfilePopup = false;
			this.adminMenuPopup = false;
			this.linksMenuPopup = false;
			this.responsiveMenuIcon= true;
			this.responsiveMenu = false;
			let access_token
			if(s.url='/access_token'){
				let token = window.location.href.split('access_token=')[1];
				if(token){
				 access_token=token.split('&token_type')[0];
				 if(access_token){
					localStorage.setItem('accessToken', access_token);        
					localStorage.setItem('loginSuccess','true');
					}
				}
			}
			if (localStorage.getItem('loginSuccess') === null) {
				this.loggedIn = 'false';
			  } else {		
				this.loggedIn = localStorage.getItem('loginSuccess');	
					if(!localStorage.getItem('currentUser')) {
						this.apiService.getAuthToken().subscribe(
						data => {	
						this.apiService.getUserDetails(access_token,true).subscribe( result => {
							localStorage.setItem('currentUser', JSON.stringify(result));
							this.currentUser = result; 
						},
						error => {});
					});
					}
			  }
			this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		}
		if(s instanceof NavigationEnd) {
			if(!localStorage.getItem('currentUser')){
				var token = localStorage.getItem('accessToken');
				this.apiService.getAuthToken().subscribe(
					data => {
						localStorage.setItem('authToken',data.accesstoken);
						this.apiService.getUserDetails(token,false).subscribe( result => {
							localStorage.setItem('currentUser', JSON.stringify(result));
							this.currentUser = result;					
							this.checkAccess(result);
			            },
						error => {});
					});		
				
			} else {
				this.checkAccess(this.currentUser);
				this.userNavigation();
			}
			this.currentUser = JSON.parse(localStorage.getItem('currentUser'));		  				
		}
	});	
	this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
}
checkAccess(user){ 
	var root = this.router.routerState.snapshot.root;
	while (root) {
		if (root.children && root.children.length) {
			root = root.children[0];
		} else if (root.params && root.params.screenId) {			   
		   if(!user.Screens.indexOf(parseInt(root.params.screenId) !== -1)){
				this.router.navigate(['databook/dashboard',1001]); 
		   }
			return;
		} else {
			return;
		}
	}
}

userNavigation()
	{
	   this.EmailId = this.currentUser.emailAddress;
		this.URL = window.location.href;
		let todaysDate = new Date();
		const date = todaysDate.getDate();
		let month = todaysDate.getMonth()+ 1;
		let year = todaysDate.getFullYear();
	    let userdata = {
			'emailid' : this.EmailId,
			'url' : this.URL,
			'date' : date,
			'month' : month,
			'year' : year
		 }
		 
		this.apiService.saveAuditScreenLogData(userdata).subscribe(
		 data => {
	 }
	 );  
	}
ngOnInit() {
	   this.getAuthToken();
            setInterval(() => {
	  this.getAuthToken();
                }, 1800000);
	// const subscription = interval(1800000)
    //   .subscribe(()=>{ 
    //     this.getAuthToken();
	//   });
	//   this.getAuthToken();
}
ngAfterViewInit() {	
 }
 getAuthToken() {
	this.apiService.getAuthToken().subscribe( result => {
		localStorage.setItem('authToken',result.accesstoken);
	},error => {
		console.log(error);
	})
 }
	toggleTreeView($event){
		if(!$event.currentTarget.parentElement.classList.contains('is-expanded')) {
			$event.currentTarget.parentElement.classList.add('is-expanded');
		} else {
			$event.currentTarget.parentElement.classList.remove('is-expanded');
		}
	}
	openUserProfilePopup(){
		this.userProfilePopup = !this.userProfilePopup;
		if(this.adminMenuPopup){
			this.adminMenuPopup = false;
		}
		if(this.linksMenuPopup){
			this.linksMenuPopup = false;
		}
	}
	openAdminPopup() {
		this.adminMenuPopup = !this.adminMenuPopup;
		if(this.userProfilePopup){
			this.userProfilePopup = false;
		}
		if(this.linksMenuPopup){
			this.linksMenuPopup = false;
		}		
	}
	openLinksPopup() {
		this.linksMenuPopup = !this.linksMenuPopup;
		if(this.userProfilePopup){
			this.userProfilePopup = false;
		}		
		if(this.adminMenuPopup){
			this.adminMenuPopup = false;
		}
	}
	toggleResponsiveMenu() {
		this.responsiveMenuIcon = !this.responsiveMenuIcon;
		this.responsiveMenu = !this.responsiveMenu;
	}
	logout(){
		localStorage.clear();
		window.location.href = environment.logoutURL;
	}
}
